import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import FilterIC from "../../assets/icons/filter-ic.svg";
import {CustomInput} from "../form-elements/custom-input";
import React from "react";
import produce from "immer";

const ClassesFilter = ({ auth, filters, setFilters, filterRes })  => {

  const updateFilters = (value, key) => {
    setFilters(filters => produce(filters, data => { data[key] = value }))
  }

  const reset = () => {
    setFilters({ name: '', end_age: '', start_age: ''})
  }

  return (
    <div className="dropdown filter" id="classes-dropdown">
      <div className="dropdown-toggle filter-toggle" id="classes-dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        <div>FILTER BY</div>
        <div className="dropdown-arrow-down">
          <img src={FilterIC} alt="filter-ic" />
        </div>
      </div>
      <div className="dropdown-menu filter-menu" aria-labelledby="#classes-dropdown-toggle">
        <div className="">
          <CustomInput
            id="name"
            type="text"
            label="Name"
            value={filters.name}
            handleChange={(e) => updateFilters(e.target.value, 'name')}
          />
        </div>
        <div className="">
          <CustomInput
            id="min-age"
            type="number"
            max="99"
            label="Min Age"
            value={filters.start_age}
            handleChange={(e) => updateFilters(e.target.value, 'start_age')}
          />
        </div>
        <div className="">
          <CustomInput
            id="max-age"
            type="number"
            max="99"
            label="Max Age"
            value={filters.end_age}
            handleChange={(e) => updateFilters(e.target.value, 'end_age')}
          />
        </div>
        <div className="row action-buttons">
          <div className="col-md-6">
            <button type="button" className="btn-primary reset-btn" onClick={reset}>
              RESET
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="btn-primary" onClick={() => filterRes(true)}>
              FILTER
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  auth: user.currentUser.token,
});

export default withRouter(connect(mapStateToProps)(ClassesFilter))