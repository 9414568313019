import React, {useState, useEffect} from "react";
import "./user-detail.styles.scss";
import {connect} from "react-redux";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {Pane, Spinner} from "evergreen-ui";
import {TopSectionNav} from "../../../../components/top-section-nav/top-section-nav";
import userImage from "../../../../assets/images/user-pexel.png";
import SearchInput from "../../../../components/general/search-input/search-input";
import {toggleUserActivation} from "../../../../utils/action/toggle-user-status";
import {formatDate, timeTo12HourFormat} from '../../../../utils/global'
import DashboardTable from "../../../../components/general/dashboard-table/dashboard-table";
import UserApi from "../../../../utils/action/users";
import ActivitiesApi from "../../../../utils/action/activities";
import user from "../../../../utils/action/users";
import {generateTableCol} from "../../../../utils/helper";
import Api from "../../../../utils/action";

const UserDetail = ({ currentUser }) => {
  const { id } = useParams();
  const [detailsDisplay, updateDetailsDisplay] = useState("details");
  const [userDetails, setUserDetails] = useState(null);
  const [profilesTable, setProfilesTable] = useState(null);
  const [subHistoryTable, setSubHistoryTable] = useState(null);
  const [activities, setActivities] = useState([]);
  const [activitiesTable, setActivitiesTable] = useState(null);

  const updateUserStatus = (status) => {
    toggleUserActivation(userDetails.id, status, currentUser.token, refetch)
  }

  const { isLoading, data, refetch } = useQuery(["userDetail"], () => UserApi.fetchUser(currentUser.token, id), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (!isLoading) {
      if (data.status) {
        setUserDetails(data.data)
      }
    }
  }, [isLoading, data])

  useEffect(() => {
    if (userDetails) {
      setProfilesTable({
        cols: [
          {title: 'Name', classes: 'no-wrap', key: 'fullName'},
          {title: 'Class', classes: '', key: 'class'},
          {title: 'Date of Birth', classes: '', key: 'dob'},
        ],
        data: userDetails.profiles.map(profile => {
          profile.fullName = `${profile.first_name} ${profile.last_name}`
          profile.dob = formatDate(new Date(profile.dob))
          profile.class = profile.classes[0].name

          return profile
        })
      })
    }
  }, [userDetails])

  useEffect(() => {
    if (userDetails) {
      const tempSubHistoryTable = {
        cols: [
            generateTableCol('Plan', 'subscription_name'),
            generateTableCol('Subscription Start Date', 'start_date'),
            generateTableCol('Expiry Date', 'end_date'),
        ],
        data: [],
      }
      
      tempSubHistoryTable.data = userDetails.subscription_history.map(history => {
        const startDate = new Date(history.transaction_date)
        const endDate = new Date(history.next_renewal)

        history.start_date = formatDate(startDate)
        history.end_date = formatDate(endDate)
        history.subscription_name = history.subscription_plans.name

        return history
      })

      setSubHistoryTable(tempSubHistoryTable)
    }
  }, [userDetails])

  useEffect(() => {
    async function fetchUserActivities() {
      const res = await Api.activities.getUserActivities(currentUser.token, userDetails.email)
      if (res.status) setActivities(res.data.result)
    }

    if (userDetails) fetchUserActivities().then().catch(e => console.error(e))
  }, [userDetails])

  useEffect(() => {
    if (userDetails) {
      const tempActivitiesTable = {
        cols: [
          generateTableCol('Action', 'activity', 'no-wrap'),
          generateTableCol('Description', 'description'),
          generateTableCol('Timestamp', 'date', 'no-wrap'),
        ],
        data: []
      }

      tempActivitiesTable.data = activities.map(activity => {
        const date = new Date(activity.created_on)
        activity.date = `${formatDate(date)} ${timeTo12HourFormat(date, true)}`
        return activity
      })

      setActivitiesTable(tempActivitiesTable)
    }
  }, [userDetails, activities])

  return (
      <div className="user_detail">
        {!userDetails || isLoading ? (
            <Pane>
              <Spinner marginX="auto" marginY={120} />
            </Pane>
        ) : (
            <>
              <TopSectionNav
                  path="/dashboard/users"
                  header="Users"
                  back="Back to Users"
              />

              <SearchInput />

              <div className="user-details-header">
                <div className="dashboard-card section-title-block">
                  <img
                      className="header-image"
                      src={userImage}
                      alt="header-image"
                  />
                  <p className="header-title">
                    {data.data.first_name} {data.data.last_name}
                  </p>
                </div>

                <div className="header-buttons">
                  {userDetails.activated ? (
                      <button className="deactivate-btn" onClick={() => updateUserStatus(false)}>DEACTIVATE</button>
                  ) : (
                      <button className="activate-btn" onClick={() => updateUserStatus(true)}>ACTIVATE</button>
                  )}
                </div>
              </div>

              <div className="dashboard-tab-view dashboard-card box7">
                <div className="dashboard-tab-heading">
                  <div className="dashboard-tab-menu">
                <span
                    onClick={() => updateDetailsDisplay("details")}
                    className={`tab-menu-item${detailsDisplay === "details" ? " active" : ""}`}
                >
                  DETAILS
                </span>
                    <span
                        onClick={() => updateDetailsDisplay("profiles")}
                        className={`tab-menu-item${detailsDisplay === "profiles" ? " active" : ""}`}
                    >
                  PROFILES
                </span>
                    <span
                        onClick={() => updateDetailsDisplay("sub-history")}
                        className={`tab-menu-item${detailsDisplay === "sub-history" ? " active" : ""}`}
                    >
                  SUBSCRIPTION HISTORY
                </span>

                    <span
                        onClick={() => updateDetailsDisplay("activities")}
                        className={`tab-menu-item${detailsDisplay === "activities" ? " active" : ""}`}
                    >
                  ACTIVITIES
                </span>
                  </div>
                </div>
                <div className="dashboard-tab-body">
                  {/*
                    <DashboardTable
                    columns={mostViewedTable.columns}
                    data={mostViewedTable.data}
                    hasMenu={true}
                    />
                  */}
                  <div className="dashboard-table-cover">
                    {
                      detailsDisplay === 'details' &&
                      <table>
                        <thead>
                        <tr className="headers">
                          <th>Full Name</th>
                          <th>Phone Number</th>
                          <th>Email Address</th>
                          <th>Profiles</th>
                          {/* <th/> */}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td className="no-wrap">{userDetails.first_name} {userDetails.last_name}</td>
                          <td>{userDetails.phone_number}</td>
                          <td>{userDetails.email}</td>
                          <td>{userDetails.profiles.length}</td>
                          {/* <td/> */}
                        </tr>
                        </tbody>
                      </table>
                    }

                    {
                      detailsDisplay === 'profiles' &&
                      profilesTable &&

                      <DashboardTable
                          tableId="profiles"
                          columns={profilesTable.cols}
                          data={profilesTable.data}
                      />
                    }

                    {
                      detailsDisplay === 'sub-history' &&
                      subHistoryTable &&
                      <DashboardTable
                          columns={subHistoryTable.cols}
                          data={subHistoryTable.data}
                      />
                    }

                    {
                      detailsDisplay === 'activities' &&
                      activitiesTable &&
                      <DashboardTable
                          columns={activitiesTable.cols}
                          data={activitiesTable.data}
                      />
                    }
                  </div>
                </div>
              </div>
            </>
        )}
      </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});
export default connect(mapStateToProps)(UserDetail);
