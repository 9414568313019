import React, { useEffect, useState } from "react";
import "./classes.styles.scss";
import useFetch from "../../../utils/data";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import SearchInput from "../../../components/general/search-input/search-input";
import { useQuery } from "react-query";
import Api from "../../../utils/action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  generateItem,
  generateItemN,
  generateTableCol,
} from "../../../utils/helper";
import DashboardTable from "../../../components/general/dashboard-table/dashboard-table";
import Paginate from "../../../components/paginate/paginate";
import DetailIcon from "../../../assets/icons/class-detail-icon.svg";
import { getSubjects, perPage } from "../../../utils/global";
import ClassModal from "../../../components/modals/class-modal";
import { Modal } from "bootstrap";
import ClassesFilter from "../../../components/classes/classes-filter";
import { setLoading } from "../../../redux/dashboard/actions";
import { toaster } from "evergreen-ui";

const Classes = ({ auth, setLoading }) => {
  const [classes, setClasses] = useState([]);
  const [classesTable, setClassesTable] = useState(null);
  const [classesPagination, setClassesPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  });
  const [subjects, setSubjects] = useState(null);
  const [classModal, setClassModal] = useState(null);
  const [selectedClassData, setSelectedClassData] = useState({
    name: "",
    description: "",
    start_age: "",
    end_age: "",
    subjects: [],
  });
  const [classesFilters, setClassesFilters] = useState({
    name: "",
    end_age: "",
    start_age: "",
  });

  const fetchClasses = async (reset = false) => {
    setLoading(true);

    const res = await Api.classes.fetchClasses(
      auth,
      classesPagination.page,
      classesFilters
    );
    if (res.status) {
      setClasses(res.data.result);
      setClassesPagination((pagination) => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
        page: reset ? 0 : pagination.page,
      }));
    }

    setLoading(false);
  };

  const addClass = () => {
    setSelectedClassData({
      name: "",
      description: "",
      start_age: "",
      end_age: "",
      subjects: [],
    });
    classModal.show();
  };

  const editClass = (cl) => {
    setSelectedClassData({
      ...cl,
      subjects: cl.subjects.map((s) => {
        return { id: s.id };
      }),
    });
    classModal.show();
  };

  const closeModal = (modal) => modal.hide();

  const saveClass = async (res) => {
    if (res && res.status) {
      classModal.hide();
      await fetchClasses();
    }
  };

  const setPage = (page) => {
    setClassesPagination((pagination) => ({ ...pagination, page: page }));
  };

  const deleteClass = async (id) => {
    setLoading(true);

    try {
      const res = await Api.classes.delete(auth, id);
      if (res.status) {
        toaster.success("Class deleted");
        await fetchClasses();
      } else toaster.danger(res.message);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchClasses()
      .then()
      .catch((e) => console.error(e));
  }, [auth, classesPagination.page]);

  useEffect(() => {
    const classModalElem = document.querySelector(".class-modal");
    if (classModalElem) setClassModal(new Modal(classModalElem));
  }, []);

  useEffect(() => {
    getSubjects(auth, (subjects) => setSubjects(subjects));
  }, [auth]);

  useEffect(() => {
    if (classes) {
      const classesTable = {
        id: "classes-table",
        cols: [
          generateTableCol("Class Name", "name"),
          generateTableCol("Description", "description"),
          generateTableCol("Age ( Min - Max )", "age_range"),
        ],
        data: classes.map((cl) => {
          cl.age_range = cl.end_age
            ? `${cl.start_age} - ${cl.end_age}`
            : `At least ${cl.start_age}`;
          cl.dropdown = {
            id: `class-${cl.id}-menu`,
            title: "",
            items: [
              generateItemN({
                text: "View",
                value: "view",
                url: `/dashboard/classes/${cl.id}`,
              }),
              generateItemN({
                text: "Edit",
                value: "edit",
                hasAction: true,
                action: () => editClass(cl),
              }),
              generateItemN({
                text: "Delete",
                value: "delete",
                classes: "text-danger",
                action: () => deleteClass(cl.id),
              }),
            ],
          };

          return cl;
        }),
      };
      setClassesTable(classesTable);
    }
  }, [classes]);

  return (
    <div className="classes">
      <SectionNav header="Classes" />

      <div className="row">
        <div className="col-md-4 me-auto">
          <ClassesFilter
            filters={classesFilters}
            setFilters={setClassesFilters}
            filterRes={fetchClasses}
          />
        </div>

        <div className="action-buttons col-auto ms-2">
          <div className="add-btn" onClick={addClass}>
            <span className="btn_text">ADD CLASS</span>
            <span className="btn-plus">+</span>
          </div>
        </div>
      </div>

      <div className="dashboard-card">
        {classesTable && (
          <DashboardTable
            columns={classesTable.cols}
            data={classesTable.data}
            hasMenu={true}
          />
        )}
      </div>

      <Paginate
        currentPage={classesPagination.page}
        totalPages={classesPagination.total}
        setPage={setPage}
      />

      <ClassModal
        auth={auth}
        closeModal={() => closeModal(classModal)}
        subjects={subjects}
        saveClassCb={saveClass}
        classData={selectedClassData}
        updateClass={(classData) => setSelectedClassData({ ...classData })}
      />
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  auth: user.currentUser.token,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Classes)
);
