import React, {useEffect, useState} from "react";
import "./subscription.styles.scss";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import {Link, useHistory} from "react-router-dom";
import DashboardStatCard from "../../../components/general/dashboard-stat-card/dashboard-stat-card";
import Api from "../../../utils/action";
import {connect} from "react-redux";
import {generateItemN} from "../../../utils/helper";
import {toaster} from "evergreen-ui";
import {formatCurrency} from "../../../utils/global";
import Empty from "../../../components/general/empty/empty";
import {setLoading} from "../../../redux/dashboard/actions";

const Subscriptions = ({ currentUser, setLoading }) => {
  const history = useHistory();
  const [selected, switchSelected] = useState(0);
  const [plans, setPlans] = useState([]);
  const [formattedPlans, setFormattedPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null)

  const fetchPlans = async () => {
    setLoading(true)

    try {
      const res = await Api.plans.fetchPlans(currentUser.token)
      if (res.status) {
        const plans = res.data.result.map(plan => {
          plan.monthly_amount = formatCurrency(plan.monthly_amount)
          plan.yearly_amount = formatCurrency(plan.yearly_amount)

          return plan
        })

        setPlans(plans)
      } else toaster.danger(res.message)
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  const deletePlan = async (id) => {
    setLoading(true)

    const res = await Api.plans.deletePlan(currentUser.token, id)
    if (res.status) {
      toaster.success('Subscription Deleted')
      await fetchPlans()
    } else toaster.danger(res.message)

    setLoading(false)
  }

  useEffect(()  => {
    fetchPlans().then().catch(e => console.error(e))
  }, [currentUser.token])

  useEffect(() => {

    if (plans.length > 0) {
      switchSelected(plans[0].id)
    }

    const newPlans = plans.map(plan => {
      plan.dropDown = {
        id: `plan-${plan.id}`,
        title: '',
        items: [
          generateItemN({
            text: 'View',
            value: 'view',
            url: `/dashboard/subscriptions/${plan.id}`,
          }),
          generateItemN({
            text: 'Edit',
            value: 'edit',
            url: `/dashboard/subscriptions/${plan.id}/edit`,
          }),
          generateItemN({
            text: 'Delete',
            value: 'delete',
            classes: 'text-danger',
            hasAction: true,
            action: () => deletePlan(plan.id),
          }),
        ],
      }

      return plan
    })

    setFormattedPlans(newPlans)
  }, [plans])

  useEffect(() => {
    const foundPlan = plans.find(plan => plan.id === selected)
    if(foundPlan) setSelectedPlan(foundPlan)
  }, [selected, plans])

  return (
      <div className="subscriptions">
        <SectionNav header="Subscription" />

        <div className="d-flex">
          <Link to="/dashboard/subscriptions/new" className="ms-auto">
            <div className="add-btn">
              <span className="btn-text">NEW SUBSCRIPTION PLAN</span>
              <span className="btn-plus">+</span>
            </div>
          </Link>
        </div>

        <div className="sub_cards sub-cards">
          {formattedPlans.map((sub) => (
              <DashboardStatCard
                  key={sub.id}
                  className={selected === sub.id ? 'sub-card active' : 'sub-card'}
                  icon={selected === sub.id ? 'plan-ic-orange.svg' : 'plan-ic.svg'}
                  title=""
                  value={`${sub.name.toUpperCase()} PLAN`}
                  clickable={true}
                  onClick={() => switchSelected(sub.id)}
                  hasDropdown={true}
                  dropDown={sub.dropDown}
              />
          ))}
        </div>

        {
          formattedPlans.length <= 0 &&
          <Empty message="Oops, You have not added any subscription plans" />
        }

        {
          selectedPlan &&
          <section className="plan-details dashboard-card">
            <div className="top-section">
              <div className="left">
                <h5>Monthly Plan</h5>
                <p>{ selectedPlan['monthly_amount'] }</p>
              </div>
              <div className="right">
                <h5>Yearly Plan</h5>
                <p>{ selectedPlan['yearly_amount'] }</p>
              </div>
            </div>
            <div className="bottom-section">
              <div className="left">
                <h5>Description</h5>
                <p>{ selectedPlan['description'] }</p>
              </div>
            </div>
          </section>
        }
      </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
