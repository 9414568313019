import { useHistory, useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./save-tests.scss";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Api from "../../../../../../utils/action";
import { TopSectionNav } from "../../../../../../components/top-section-nav/top-section-nav";
import { CustomInput, CustomSelectInput, CustomTextarea } from "../../../../../../components/form-elements/custom-input";
import Add from "../../../../../../assets/icons/add-black-ic.svg";
import DeleteIcon from "../../../../../../assets/icons/trash-black-ic.svg";
import EditIcon from "../../../../../../assets/icons/edit-ic.svg";
import { generateItem, question, termDropdown } from "../../../../../../utils/helper";
import { Spinner, toaster } from "evergreen-ui";
import { letters } from "../../../../../../utils/global";
import produce from "immer";
import { setLoading } from "../../../../../../redux/dashboard/actions";

const SaveTests = ({ currentUser, setLoading }) => {
  const history = useHistory();
  const { classId, subjectId, id, action } = useParams();
  const [classDetails, setClassDetails] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [prerequisiteLessonDetails, setPrerequisiteLessonDetails] = useState({});
  const [lessonsDropdown, setLessonsDropdown] = useState(null);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [deletedOptions, setDeletedOptions] = useState([]);
  const [readOnly, setReadonly] = useState(false);

  const [terms, setTerms] = useState({
    first: "First Term",
    second: "Second Term",
    third: "Third Term",
  });

  const [test, setTest] = useState({
    class_id: classId,
    subject: "",
    term: "first",
    title: "",
    description: "",
    instructions: "",
    pass_mark: 0,
    no_of_retries: 0,
    prerequisite_lesson_id: "",
    lesson: "",
    questions: [question()],
  });

  const fetchTest = async () => {
    setLoading(true);
    const res = await Api.tests.fetchById(currentUser.token, id);
    if (res.status) {
      setTest(res.data);
      await fetchSubject();
    }
    setLoading(false);
  };

  const fetchClass = async () => {
    setLoading(true);
    const res = await Api.classes.fetchClass(currentUser.token, classId);
    if (res.status) setClassDetails(res.data);
    setLoading(false);
  };

  const fetchSubject = async () => {
    setLoading(true);
    const res = await Api.classes.fetchSubjectById(currentUser.token, subjectId);
    if (res.status)
      setTest((test) =>
        produce(test, (data) => {
          data.subject = res.data.name;
        })
      );
    setLoading(false);
  };

  const fetchLessons = async () => {
    setLoading(true);
    const res = await Api.lessons.fetch(currentUser.token, 0, classId, subjectId, test.term);
    if (res.status) setLessons(res.data.result);
    setLoading(false);
  };

  const updateTest = (key, val) => {
    if (readOnly) return;
    setTest((test) =>
      produce(test, (data) => {
        data[key] = val;
      })
    );
  };

  const saveQuestion = (id) => {
    const question = { ...test.questions[id], editing: false };
    updateQuestions(id, question);
  };

  const newQuestion = () => {
    updateTest(
      "questions",
      produce(test.questions, (data) => {
        data.push(question());
      })
    );
  };

  const updateQuestion = (id, key, value) => {
    updateTest(
      "questions",
      produce(test.questions, (data) => {
        data[id][key] = value;
      })
    );
  };

  const updateOption = (qid, id, value) => {
    updateTest(
      "questions",
      produce(test.questions, (data) => {
        data[qid].options[id].option = value;
      })
    );
  };

  const setOptionCorrect = (qid, id) => {
    updateTest(
      "questions",
      produce(test.questions, (data) => {
        data[qid].options = data[qid].options.map((option) => {
          option.correct = false;
          return option;
        });
        data[qid].options[id].correct = true;
      })
    );
  };

  const updateQuestions = (id, question) => {
    updateTest(
      "questions",
      produce(test.questions, (data) => {
        data.splice(id, 1, question);
      })
    );
  };

  const removeFromQuestions = (id) => {
    if (test.questions.length === 1) {
      toaster.danger("Test must have at least on question");
      return;
    }

    updateTest(
      "questions",
      produce(test.questions, (data) => {
        const soft = !!data[id].id;
        if (soft) data[id].deleted = true;
        else data.splice(id, 1);
      })
    );
  };

  const saveTest = async () => {
    setLoading(true);

    const data = {
      title: test.title,
      description: test.description,
      instructions: test.instructions,
      pass_mark: test.pass_mark,
      no_of_retries: test.no_of_retries,
      subject: test.subject,
      class_id: test.class_id,
      term: test.term,
      prerequisite_lesson_id: test.prerequisite_lesson_id,
      questions: test.questions.map((q) => {
        const question = {
          question: q.question,
          optional: false,
          options: q.options.map((opt) => {
            const option = { option: opt.option, correct: opt.correct };

            if (opt.id) {
              option.id = opt.id;
              option.delete = deletedOptions.includes(opt.id);
            }

            return option;
          }),
        };

        if (q.id) {
          question.id = q.id;
          question.delete = deletedQuestions.includes(q.id);
        }

        return question;
      }),
    };

    if (test.id) {
      Api.tests.update(currentUser.token, test.id, data, (res) => {
        if (res.status) {
          toaster.success("Test Updated successfully");
          history.push(`/dashboard/classes/${classId}/${subjectId}`);
        } else toaster.danger(res.message);

        setLoading(false);
      });
    } else {
      Api.tests.create(currentUser.token, data, (res) => {
        if (res.status) {
          toaster.success("Test created successfully");
          history.push(`/dashboard/classes/${classId}/${subjectId}`);
        } else toaster.danger(res.message);

        setLoading(false);
      });
    }
  };

  const findLessonName = async (id) => {
    if (id && id !== "") {
      const found = lessons.find((lesson) => lesson.id === id);
      if (found) setPrerequisiteLessonDetails(found);
      else {
        const res = await Api.lessons.fetchById(currentUser.token, id);
        if (res.status) setPrerequisiteLessonDetails(res.data);
      }
    }
  };

  let questionsCount = 1;

  useEffect(() => {
    if (id)
      fetchTest()
        .then(() => {})
        .catch((e) => console.error(e));
    else
      fetchSubject()
        .then(() => {})
        .catch((e) => console.error(e));
  }, [currentUser.token, id]);

  useEffect(() => {
    fetchClass()
      .then(() => {})
      .catch((e) => console.error(e));
  }, [classId, currentUser.token]);

  useEffect(() => {
    fetchLessons()
      .then(() => {})
      .catch((e) => console.error(e));
  }, [test.term, classId, subjectId, currentUser.token]);

  useEffect(() => {
    if (action === "view") setReadonly(true);
  }, [action]);

  useEffect(() => {
    const lessonsDropdown = {
      id: "test-lessons-select",
      title: "Select Prerequisite Lesson",
      items: lessons.map((lesson) => {
        return generateItem(lesson.name, lesson.id);
      }),
    };

    setLessonsDropdown(lessonsDropdown);
  }, [lessons]);

  useEffect(() => {
    findLessonName(test.prerequisite_lesson_id)
      .then()
      .catch((e) => console.error(e));
  }, [test.prerequisite_lesson_id]);

  return (
    <div className="save-test-page">
      <TopSectionNav path={`/dashboard/classes/${classId}/${subjectId}`} header="Test" back="Back to Subject" />

      <form className="save-test-form">
        <div className="details row">
          <div className="col-md-4 mt-3">
            <CustomInput id="class" name="class" type="text" label="Class" defaultValue={classDetails ? classDetails.name : ""} readOnly />
          </div>

          <div className="col-md-4 mt-3">
            <CustomInput id="subject" name="subject" type="text" label="Subject" defaultValue={test.subject} readOnly />
          </div>

          <div className="col-md-4 mt-3">
            <CustomSelectInput id="terms-select" name="term" dropDown={termDropdown()} label="Term" selected={{ text: terms[test.term], value: test.term }} onChange={(term) => updateTest("term", term.value)} readOnly={readOnly} />
          </div>

          <div className="col-md-6 mt-3">
            <CustomInput id="title" name="title" type="text" label="Title / Header" value={test.title} handleChange={(e) => updateTest("title", e.target.value)} readOnly={readOnly} />
          </div>

          <div className="col-md-3 mt-3">
            <CustomInput id="passMark" name="passMark" type="number" label="Passmark (%)" value={test.pass_mark} handleChange={(e) => updateTest("pass_mark", e.target.value)} readOnly={readOnly} />
          </div>

          <div className="col-md-3 mt-3">
            <CustomInput id="no-of-retries" name="noOfRetries" type="number" label="Number Of Retries" value={test.no_of_retries} handleChange={(e) => updateTest("no_of_retries", e.target.value)} readOnly={readOnly} />
          </div>

          <div className="col-md-6 mt-3">
            <CustomTextarea id="instructions" name="instructions" type="text" placeholder="Instructions" label="Instructions" value={test.instructions} handleChange={(e) => updateTest("instructions", e.target.value)} readOnly={readOnly} />
          </div>

          <div className="col-md-6 mt-3">
            <CustomTextarea id="description" name="description" type="text" placeholder="Description" label="Description" value={test.description} handleChange={(e) => updateTest("description", e.target.value)} readOnly={readOnly} />
          </div>
        </div>
        <div className="questions">
          {test.questions.map(
            (question, id) =>
              !deletedQuestions.includes(question.id) && (
                <div className="question-cover" key={`question-${id}`}>
                  <div className={`question ${!question.editing ? "no-edit" : ""}`}>
                    <div className="row">
                      <div className="col-auto">
                        <div className="question-number">
                          <span>{questionsCount++}</span>
                        </div>
                        {!readOnly && (
                          <div className="add-question-btn" onClick={newQuestion}>
                            <img src={Add} alt="add-ic" />
                          </div>
                        )}
                      </div>
                      <div className="col">
                        <div className="form-group question-text">
                          <input className="dashboard-input mt-0" type="text" value={question.question} onChange={(e) => updateQuestion(id, "question", e.target.value)} />
                        </div>
                        <div className="options">
                          {question.options.map((option, optionId) => (
                            <div className="option col-md-6" key={`${id}-option-${optionId}`}>
                              <div className="dashboard-input">
                                <div className={`letter ${option.correct ? "is-correct" : ""}`} onClick={() => setOptionCorrect(id, optionId)}>
                                  {letters[optionId]}
                                </div>
                                <input type="text" value={option.option} onChange={(e) => updateOption(id, optionId, e.target.value)} />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-auto">
                        {!readOnly && (
                          <div className="actions d-flex flex-column">
                            <div className="btn btn-primary done-btn" onClick={() => saveQuestion(id)}>
                              DONE
                            </div>
                            <div className="action-buttons mt-auto d-flex justify-content-around">
                              <div className="edit-btn action-button" onClick={() => updateQuestion(id, "editing", true)}>
                                <img src={EditIcon} alt="edit-ic" />
                              </div>
                              <div className="delete-btn action-button" onClick={() => removeFromQuestions(id)}>
                                <img src={DeleteIcon} alt="delete-ic" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              )
          )}
        </div>
        <div className="prerequisite-lesson-cover row mt-5">
          <div className="col-md-4 mx-auto">
            {lessonsDropdown && (
              <CustomSelectInput
                id="prerequisite-lesson"
                name="lessons"
                dropDown={lessonsDropdown}
                label="Test Prerequisite"
                selected={{ text: prerequisiteLessonDetails.name, value: test.prerequisite_lesson_id }}
                onChange={(item) => updateTest("prerequisite_lesson_id", item.value)}
                readOnly={readOnly}
              />
            )}
          </div>
        </div>
        {!readOnly && (
          <div id="publish-cover" className="publish mt-5 mb-2 d-flex justify-content-center align-items-center">
            <button type="button" className="btn-primary" onClick={saveTest}>
              PUBLISH
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveTests));
