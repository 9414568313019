import "./landing-section-nav.styles.scss";
import NotificationBar from "../notification-bar/notification-bar.component";

const SectionNav = ({ header }) => {
  return (
    <div className="landing_section_nav">
      <NotificationBar {...{header}} className="notification" />
    </div>
  );
};

export default SectionNav;
