import "./course-modules.scss"
import { connect } from "react-redux";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import { useCallback, useEffect, useState } from "react";
import Api from "../../../utils/action";
import { withRouter } from "react-router-dom";
import SortTable from "../../../components/general/sort-table";
import { setLoading } from "../../../redux/dashboard/actions";
import { Chip, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";


const CourseModules = ({ currentUser }) => {
    const [data, setdata] = useState([]);
    const [search, setSearch] = useState("");

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            width: '20%',
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: 'Description',
            width: '40%',
        },
        {
            id: 'price',
            numeric: false,
            disablePadding: true,
            label: 'Amount',
            width: '100px',
        },
        {
            id: 'approved',
            numeric: false,
            disablePadding: true,
            label: 'Status',
            width: '100px',
            formatter: (value) => {
                let content
                if (value) content = "Published";
                else content = "Unpublished";
                return <Chip label={
                    <>
                        <svg className="me-2" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4" cy="4" r="3" fill="#1259B7" />
                        </svg>
                        {content}
                    </>} />
            }
        },
        {
            id: 'created_on',
            numeric: false,
            disablePadding: true,
            label: 'Date',
            width: '20%',
            formatter: (value) => {
                return new Date(value).toISOString().replace(/-/g, '/').replace('T', ' ').slice(0, -5);
            }
        },
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '',
            formatter: (value) => {
                return <MuiLink component={Link} className="" sx={{ color: '#ff6c00', textDecoration: "none" }} to={`/dashboard/course-modules/${value}`}>View</MuiLink>;
            }
        },
    ]

    const fetchLessons = useCallback(() => (
        (async () => {
            setLoading(true)
            const res = await Api.tutors.fetchTutorsCourseModules(currentUser.token, 0, [["name", search]])
            if (res.status) setdata(res.data.result)
            setLoading(false)
        })()
    ), [currentUser.token, search])

    useEffect(() => { fetchLessons(); }, [currentUser.token, fetchLessons])

    return (
        <div className="profile-page-cover">
            <SectionNav header="Course Modules" />

            <div className="dashboard-card">
                <div className="dashboard-tab-view">
                    <div className="dashboard-tab-heading">
                        <div className="dashboard-tab-menu">Course Modules</div>
                    </div>
                    <div className="dashboard-tab-body">
                        <div>
                            <input type="search" name="search" value={search} onChange={(e) => setSearch(e.target.value)} className="d-none form-control" id="search"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        fetchLessons();
                                    }
                                }}
                            />
                        </div>
                        {data.length ? <SortTable {...{ data, headCells, options: { orderBy: 'id', order: 'asc', style: { padding: "10px" } } }} /> : "No data found"}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user }) => ({
    currentUser: user.currentUser,
});

export default withRouter(connect(mapStateToProps)(CourseModules));