import Transactions from "./transactions";
import Teammates from "./teammates";
import Activities from "./activities";
import Permissions from "./permissions";
import Account from "./account";
import Classes from "./classes";
import Lessons from "./lessons";
import Livestream from "./livestream";
import Plans from "./plans";
import Dashboard from "./dashboard";
import Role from "./role";
import Tests from "./tests";
import Users from "./users";
import Tutors from "./tutor";

const Api = {
  dashboard: Dashboard,
  transactions: Transactions,
  teammates: Teammates,
  activities: Activities,
  permission: Permissions,
  account: Account,
  classes: Classes,
  lessons: Lessons,
  livestreams: Livestream,
  plans: Plans,
  role: Role,
  tests: Tests,
  users: Users,
  tutors: Tutors,
};

export default Api;
