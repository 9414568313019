import React, {useEffect, useState} from "react";
import "./add-livestream.styles.scss";
import {TopSectionNav} from "../../../../components/top-section-nav/top-section-nav";
import {toaster} from "evergreen-ui";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import SaveLivestreamForm from "../../../../components/livestream/save-livestream/save-livestream-form";
import {produce} from "immer";

const AddLivestream = ({auth}) => {
  const history = useHistory();
  const {id} = useParams();
  const [livestream, setLivestream] = useState({
    title: '',
    class_id: '',
    description: '',
    tutor_id: '',
    tags: [],
    subject: '',
    reminder: '',
    event_date: '',
    duration: 0,
    thumbnail: '',
  })


  const updateLivestream = (key, val) => {
    setLivestream(livestream => {
      return produce(livestream, data => {
        data[key] = val
      })
    })
  }

  const savedLivestream = () => {
    toaster.success('Livestream created successfully')
    history.push(`/dashboard/livestream/`)
  }


  return (
    <div className="add-livestream-page">

      <TopSectionNav
        path="/dashboard/livestream"
        header="Livestream"
        back="Back to Live Stream"
      />
      <h1>ADD A LIVESTREAM</h1>

      {
        livestream &&
        <SaveLivestreamForm
          auth={auth}
          livestream={livestream}
          updateLivestream={updateLivestream}
          savedLivestream={savedLivestream}
        />
      }
    </div>
  );
};

const mapStateToProps = ({user}) => ({
  auth: user.currentUser.token,
});

export default withRouter(connect(mapStateToProps)(AddLivestream));
