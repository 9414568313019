import ApiCalls from "./api";

const Livestream = {
  fetchLivestreams: async (auth, page) => await ApiCalls.getForQuery(auth, "admin/live", page),
  fetchLivestream: async (auth, id) => await ApiCalls.getById(auth, "admin/live", id),
  create: (auth, data) => ApiCalls.postForResAsync(auth, "admin/live", data),
  update: (auth, id, data) => ApiCalls.patchForResAsync(auth, `admin/live/${id}`, data),
  deleteLiveStream: (auth, id, cb) => ApiCalls.deleteReq(auth, `admin/live`, id, cb),
  initializeLivestream: async (auth, id) => await ApiCalls.postForResAsync(auth, `admin/live/${id}/init`),
  startLivestream: async (auth, id) => await ApiCalls.postForResAsync(auth, `admin/live/${id}/start`, {}),
  endLivestream: async (auth, id) => await ApiCalls.putForRes(auth, `admin/live/${id}/stop`, {}),
  eventAction: async (auth, id, data) => await ApiCalls.putForRes(auth, `admin/live/${id}/action`, data),
};
export default Livestream;
