import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import "./live-stream.styles.scss";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import SearchInput from "../../../components/general/search-input/search-input";
import Api from "../../../utils/action";
import {generateItemN, generateTableCol} from "../../../utils/helper";
import {connect} from "react-redux";
import Paginate from "../../../components/paginate/paginate";
import DashboardTable from "../../../components/general/dashboard-table/dashboard-table";
import {formatFullDate, perPage} from "../../../utils/global";
import {setLoading} from "../../../redux/dashboard/actions";

const LiveStream = ({currentUser, setLoading}) => {
  const [pagination, setPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  });
  const [liveStreams, setLiveStream] = useState(null);
  const [liveStreamsTable, setLiveStreamTable] = useState(null);
  const [liveStreamsPagination, setLiveStreamsPagination] = useState(null);

  const fetchLivestreams = async (reset = false) => {
    setLoading(true)
    const page = reset ? 0 : pagination.page

    const res = await Api.livestreams.fetchLivestreams(currentUser.token, page)
    if (res.status) {
      setLiveStream(res.data.result)
      setPagination(pagination => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
        page: reset ? 0 : pagination.page
      }))
    }

    setLoading(false)
  }

  const setPage = (page) => {
    setPagination(pagination => ({...pagination, page: page}))
  }

  useEffect(() => {
    fetchLivestreams().then().catch(e => console.error(e))
  }, [currentUser.token, pagination.page])

  useEffect(() => {
    if (liveStreams) {
      const liveStreamsTable = {
        id: 'livestream-table',
        cols: [
          generateTableCol('Name', 'title', 'no-wrap'),
          generateTableCol('Tutor Name', 'tutor_name', 'no-wrap'),
          generateTableCol('Class', 'class_name', 'no-wrap'),
          generateTableCol('Event Time', 'event_start_time', 'no-wrap'),
          generateTableCol('Status', 'status_formatted', 'no-wrap'),
          generateTableCol('Approved', 'approved', 'no-wrap'),
        ],
        data: liveStreams.map(liveStream => {
          liveStream.event_start_time = formatFullDate(new Date(liveStream.event_start_time))
          liveStream.class_name = liveStream.class ? liveStream.class.name : ''
          liveStream.status_formatted = liveStream.status
          liveStream.approved = liveStream.approved === false ? "Pending" : "Approved";

          const menuItems = {
            view: generateItemN({text: 'View', url: `/dashboard/livestream/${liveStream.id}`}),
            initiate: generateItemN({
              text: 'Initiate',
              value: 'initiate',
              classes: 'text-warning',
              url: `/dashboard/livestream/${liveStream.id}/view`
            }),
            start: generateItemN({
              text: 'Start',
              value: 'start',
              classes: 'text-success',
              url: `/dashboard/livestream/${liveStream.id}/view`
            }),
            join: generateItemN({
              text: 'Join',
              value: 'join',
              classes: 'text-success',
              url: `/dashboard/livestream/${liveStream.id}/view`
            }),
            edit: generateItemN({text: 'Edit', value: 'edit', url: `/dashboard/livestream/${liveStream.id}/edit`}),
          }
          const dropdownItems = [menuItems.view]

          if (liveStream.status.toLowerCase() === 'done') {
            liveStream.status_formatted = {classes: 'text-success', value: 'Done'}
          } else if (liveStream.status.toLowerCase() === 'started') {
            dropdownItems.push(menuItems.join)
            liveStream.status_formatted = {classes: 'text-success', value: 'Started'}
          } else if (liveStream.token && liveStream.session_id) {
            dropdownItems.push(menuItems.start)
            liveStream.status_formatted = {classes: 'text-warning', value: 'Initialized'}
          } else if (liveStream.status === 'PENDING') {
            dropdownItems.push(menuItems.initiate)
            liveStream.status_formatted = {classes: 'text-warning', value: 'Pending'}
          }

          if (liveStream.status !== 'DONE') {
            dropdownItems.push(menuItems.edit)
          }

          liveStream.dropdown = {
            id: `class-${liveStream.id}-menu`,
            title: '',
            items: [
              ...dropdownItems,
            ],
          }

          return liveStream
        })
      }

      setLiveStreamTable(liveStreamsTable)
    }
  }, [liveStreams])

  return (
    <div className="livestream classes">
      <SectionNav header="Livestream"/>

      <div className="row">
        <div className="col-auto">
          <SearchInput/>
        </div>
        <div className="col-auto ms-auto">
          <Link to="/dashboard/add-livestream" className="ms-auto">
            <div className="add-btn">
              <span className="btn-text">CREATE A LIVE STREAM</span>
              <span className="btn-plus">+</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="dashboard-card">
        {
          liveStreamsTable &&
          <DashboardTable
            columns={liveStreamsTable.cols}
            data={liveStreamsTable.data}
            hasMenu={true}
          />
        }
      </div>

      <Paginate
        currentPage={pagination.page}
        totalPages={pagination.total}
        setPage={setPage}
      />
    </div>
  );
};

const mapStateToProps = ({user}) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveStream));
