import React, { useState } from "react";
import "./notification.styles.scss";
import { TiArrowSortedDown } from "react-icons/ti";
import { connect } from "react-redux";
import { LogOut } from "../../redux/admin-user/admin.actions";
import { useHistory } from "react-router-dom";

const NotificationBar = ({ LogOut, currentUser, header }) => {
  const history = useHistory();
  const [logout, setLogout] = useState(false);
  // console.log(currentUser);

  const handleLogOut = () => {
    localStorage.clear();
    LogOut();
    history.push("/");
  };
  const gotoProfile = () => history.push('/dashboard/profile')

  return (
    <div className="notification w-100 d-flex justify-content-between align-items-center">
      <div></div>
      <h3 className="m-0">{header}</h3>
      <div className="dropdown profile-dropdown-cover">
        <div className="dropdown-toggle" id="profile-dropdown-toggle" role="button" aria-haspopup="true" data-bs-toggle="dropdown" aria-expanded="false">
          <span>{currentUser.first_name}</span>
          <TiArrowSortedDown className="arrow" />
        </div>
        <ul className="dropdown-menu mt-2" aria-labelledby="#profile-dropdown-toggle">
          <li className="dropdown-item" id="active" onClick={gotoProfile}>Profile</li>
          <li className="dropdown-item" id="logout" onClick={handleLogOut}>Logout</li>
        </ul>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  LogOut: () => dispatch(LogOut()),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
