import {useHistory, useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {produce} from "immer"
import Api from "../../../../../../utils/action";
import SaveLessonForm from "../../../../../../components/lesson/save-lesson-form/save-lesson-form";
import {TopSectionNav} from "../../../../../../components/top-section-nav/top-section-nav";
import {toaster} from "evergreen-ui";
import {setLoading} from "../../../../../../redux/dashboard/actions";

const EditLesson = ({currentUser, lastUploadedVideo, setLoading}) => {

  const history = useHistory()
  const {classId, subjectId, lessonId, action} = useParams()
  const [lesson, setLesson] = useState(null)
  const [readOnly, setReadOnly] = useState(false)

  const fetchLesson = async () => {
    setLoading(true)

    const res = await Api.lessons.fetchById(currentUser.token, lessonId)
    if (res.status) {
      const lesson = res.data

      lesson.subject = lesson.subjects.name
      lesson.topic = lesson.name
      lesson.tutor_name = lesson.tutor_name ? lesson.tutor_name : ''
      lesson.tutor_bio = lesson.tutor_bio ? lesson.tutor_bio : ''

      lesson.videos = lesson.videos.map(vid => ({
        ...vid,
        url: vid.original_url,
        delete: false,
      }))

      lesson.documents = lesson.documents.map(doc => ({
        ...doc,
        title: doc.name,
        delete: false,
      }))

      setLesson(res.data)
    }

    setLoading(false)
  }

  const updateLesson = (key, val) => {
    setLesson(lesson => {
      return produce(lesson, data => {
        data[key] = val
      })
    })
  }

  const savedLesson = (res) => {
    toaster.success('Lesson updated successfully')
    history.push(`/dashboard/classes/${classId}/${subjectId}`)
  }

  useEffect(() => {
    if (action.toLowerCase() === 'view') setReadOnly(true)
    else setReadOnly(false)
  }, [action])

  useEffect(() => {
    fetchLesson().then().catch(e => console.error(e))
  }, [currentUser.token, lessonId])

  useEffect(() => {
    if (lastUploadedVideo && lastUploadedVideo.lessonId === lessonId) {
      fetchLesson().then().catch(e => console.error(e))
    }
  }, [lastUploadedVideo])

  return (
    <div className="add-lesson-page">
      <TopSectionNav
        path={`/dashboard/classes/${classId}/${subjectId}`}
        header="Lesson"
        back={`Back to ${lesson && lesson.subject}`}
      />

      {action.toLowerCase() === 'edit' && <h1>EDIT A LESSON</h1>}
      {action.toLowerCase() === 'view' && <h1>VIEW LESSON</h1>}

      <SaveLessonForm
        auth={currentUser.token}
        lesson={lesson}
        updateLesson={updateLesson}
        classId={classId}
        savedLesson={savedLesson}
        readOnly={readOnly}
      />
    </div>
  )
}

const mapStateToProps = ({user, dashboard}) => ({
  currentUser: user.currentUser,
  lastUploadedVideo: dashboard.lastUploadedVideo
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditLesson))