// import logo from './logo.svg';
import "../../assets/scss/App.scss";
import { GlobalStyle } from "../global-styles/global-styles";
import { Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "../../pages/login-page/login-page.component";
import ResetPassword from "../../pages/reset-password/reset-password.component";
import DashboardPage from "../../pages/dashboard-page/dashboard";
import CompleteProfile from "../../pages/complete-profile/complete-profile";
import { connect } from "react-redux";
// import ProtectedRoute from "../../utils/auth/protected.route";
import { useEffect, useState } from "react";

function App({ currentUser }) {
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    const currentUser = localStorage.getItem("user");

    setUserToken(currentUser);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <GlobalStyle />

      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            currentUser || (userToken && userToken.currentUser) ? (
              <Redirect to="/dashboard" />
            ) : (
              <LoginPage />
            )
          }
        />

        <Route path="/reset-password" component={ResetPassword} />
        <Route
          path="/complete-profile"
          render={() =>
            currentUser ? <Redirect to="/dashboard" /> : <CompleteProfile />
          }
        />
        {/* <Route path="/dashboard" component={DashboardPage} /> */}

        <Route
          path="/dashboard"
          render={() =>
            currentUser || (userToken && userToken.currentUser) ? <DashboardPage /> : <Redirect to="/" />
          }
        />
        <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(mapStateToProps)(App);
