import React from "react";
import { LoginLayout } from "../../components/Login-layout/login-layout.component";
import Button from "../../components/custom-button/custom-button.component";
import "./complete-profile.styles.scss";
import logo from "../../assets/images/dyeka-logo-4a.png";
import FormInput from "../../components/form-input/form-input";
import FormPasswordInput from "../../components/form-password-input/form-password-input";
import { setcurrentUser } from "../../redux/admin-user/admin.actions";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router";
import queryString from "query-string";
import { toaster, Spinner } from "evergreen-ui";
import {baseUrl} from "../../utils/global";

const CompleteProfileSchema = Yup.object().shape({
  first_name: Yup.string().required("Firstname is required"),

  last_name: Yup.string().required("Lastname is required"),

  password: Yup.string().required("Password is required"),
  // .min(4, "Password is too short - should be 4 chars minimum"),

  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const CompleteProfile = ({ setcurrentUser }) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
  };

  const [isLoading, setLoading] = React.useState(false);

  // console.log(match);
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const submitForm = (values) => {
    setLoading(true);

    const { first_name, last_name, password } = values;
    fetch(`${baseUrl}/admin/team?token=${parsed.token}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${parsed.token}`,
      },
      body: JSON.stringify({
        first_name,
        last_name,
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data.token);
        setLoading(false);
        if (data.message !== "Successful") {
          toaster.danger(`${data.message}`);
        }

        if (data.status === true) {
          setcurrentUser(data.data);
          localStorage.setItem("user", data.data.token);
          toaster.success(`${data.message}`);
        }
      });
  };

  return (
    <div className="auth-cover">
      <div className="auth-background">
        <LoginLayout heading="Admin Dashboard" name="Complete Profile" />
      </div>
      <div className="auth-form-cover">
        <div className="auth-form">
          <Formik
              initialValues={initialValues}
              onSubmit={submitForm}
              validationSchema={CompleteProfileSchema}
          >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                dirty,
                isValid,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h3 className="auth-form-heading">
                    <span className="admin">Admin Profile</span>
                    <span>
                  <img src={logo} alt="logo" />
                </span>
                  </h3>
                  <p className="auth-form-intro">
                  </p>

                  <div className="name-cover">
                    <FormInput
                        name="first_name"
                        handleChange={handleChange}
                        value={values.first_name}
                        label="First Name"
                        type="text"
                        onBlur={handleBlur}
                        errorText={
                          errors.first_name && touched.first_name
                              ? `${errors.first_name}`
                              : null
                        }
                    />
                    <FormInput
                        name="last_name"
                        handleChange={handleChange}
                        value={values.last_name}
                        label="Last Name"
                        type="text"
                        onBlur={handleBlur}
                        errorText={
                          errors.last_name && touched.last_name
                              ? `${errors.last_name}`
                              : null
                        }
                    />
                  </div>

                  <FormPasswordInput
                      name="password"
                      handleChange={handleChange}
                      value={values.password}
                      label="Enter Password"
                      onBlur={handleBlur}
                      errorText={
                        errors.password && touched.password
                            ? `${errors.password}`
                            : null
                      }
                  />
                  <FormPasswordInput
                      name="confirmPassword"
                      handleChange={handleChange}
                      value={values.confirmPassword}
                      label="Confirm Password"
                      onBlur={handleBlur}
                      errorText={
                        errors.confirmPassword && touched.confirmPassword
                            ? `${errors.confirmPassword}`
                            : null
                      }
                  />

                  <Button type="submit" disabled={!(dirty && isValid)}>
                    {isLoading && <Spinner size={16} />}
                    <span> LOGIN</span>
                  </Button>
                </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setcurrentUser: (admin) => dispatch(setcurrentUser(admin)),
});

export default connect(null, mapDispatchToProps)(CompleteProfile);
