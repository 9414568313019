import React, { useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "./dashboard.styles.scss";
import Menu from "../../components/dashboard-menu/dashboard-menu.component";
import Activity from "../dashboard-sections/activities/activities.component";
import Home from "../dashboard-sections/dashboard-home/home.component";
import Settings from "../dashboard-sections/dashboard-setting/setting";
import Classes from "../dashboard-sections/dashboard-classes/classes";
import AuditTrail from "../dashboard-sections/audit-trail/audit-trail";
import Users from "../dashboard-sections/users/users";
import UserDetail from "../dashboard-sections/users/user-detail/user-detail";
import LiveStream from "../dashboard-sections/live-stream/live-stream";
import LivestreamDetail from "../dashboard-sections/live-stream/livestream-detail/livestream-detail";
import AddLivestream from "../dashboard-sections/live-stream/add-livestream/add-livestream";
import HomeDetailPage from "../dashboard-sections/dashboard-home/dashboard-home-detail/dashboard-home-detail";
import Subscriptions from "../dashboard-sections/subscriptions/subscriptions";
import PlanDetails from "../dashboard-sections/subscriptions/plan-details/plan-details";
import AddPlan from "../dashboard-sections/subscriptions/save-plan/add-plan";
import ToggleButton from "../../components/toggle-button/toggle-button";
import Transactions from "../dashboard-sections/transactions/transactions";
import Profile from "../dashboard-sections/profile/profile";
import ClassDetail from "../dashboard-sections/dashboard-classes/class-detail/class-detail";
import AddLesson from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/add-lesson/add-lesson";
import SubjectDetail from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/subject-detail";
import SaveTests from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/save-test/save-tests";
import ViewLivestream from "../dashboard-sections/live-stream/initialize-livestream/view-livestream";
import EditLesson from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/edit-lesson/edit-lesson";
import { connect } from "react-redux";
import EditLivestream from "../dashboard-sections/live-stream/add-livestream/edit-livestream";
import EditPlan from "../dashboard-sections/subscriptions/save-plan/edit-plan";
import courseModules from "../dashboard-sections/course-modules/course-modules";
import liveClasses from "../dashboard-sections/live-classes/live-classes";
import Assignments from "../dashboard-sections/assignments/assignments";
import Analytics from "../dashboard-sections/analytics/analytics";

const DashboardPage = ({
  currentUser,
  loading,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const renderAdminRoutes = () => {
    if (currentUser.role_id) {
      return <>
        <Route exact path="/dashboard/users" component={Users} />
        <Route path="/dashboard/users/:id" component={UserDetail} />
        <Route path="/dashboard/activities" component={Activity} />
        <Route path="/dashboard/audit-trail" component={AuditTrail} />

        <Route
          exact
          path="/dashboard/subscriptions"
          component={Subscriptions}
        />
        <Route
          exact
          path="/dashboard/subscriptions/new"
          component={AddPlan}
        />
        <Route
          exact
          path="/dashboard/subscriptions/:id"
          component={PlanDetails}
        />
        <Route
          exact
          path="/dashboard/subscriptions/:id/edit"
          component={EditPlan}
        />
        <Route path="/dashboard/transactions" component={Transactions} />
      </>
    }
    else {
      return <>
        <Route path="/dashboard/course-modules" component={courseModules} />
        <Route path="/dashboard/live-classes" component={liveClasses} />
        <Route path="/dashboard/assignments" component={Assignments} />
        <Route path="/dashboard/analytics" component={Analytics} />

      </>
    }
  }

  return (
    <div className="dashboard">
      <div
        className={
          isMobile ? "active dashboard-leftcolumn" : "dashboard-leftcolumn"
        }
      >
        <Menu {...{ isMobile, role: currentUser.role }} />
      </div>
      <div className="dashboard-rightcolumn">
        <div
          className="toggler"
          onClick={() => {
            setIsMobile((prev) => !prev);
          }}
        >
          <ToggleButton />
        </div>

        <Switch>
          <Route exact path="/dashboard" component={currentUser.role_id ? Home : courseModules} />
          <Route
            exact
            path="/dashboard/detail/:id"
            component={HomeDetailPage}
          />
          <Route exact path="/dashboard/settings" component={Settings} />
          <Route exact path="/dashboard/classes" component={Classes} />
          <Route exact path="/dashboard/classes/:id" component={ClassDetail} />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId"
            component={SubjectDetail}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/add-lesson"
            component={AddLesson}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/lesson/:lessonId/:action"
            component={EditLesson}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/add-test"
            component={SaveTests}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/test/:id/:action"
            component={SaveTests}
          />
          <Route exact path="/dashboard/livestream" component={LiveStream} />
          <Route
            exact
            path="/dashboard/livestream/:id"
            component={LivestreamDetail}
          />
          <Route
            exact
            path="/dashboard/livestream/:id/edit"
            component={EditLivestream}
          />
          <Route
            exact
            path="/dashboard/livestream/:id/view"
            component={ViewLivestream}
          />
          <Route path="/dashboard/add-livestream" component={AddLivestream} />
          <Route path="/dashboard/profile" component={Profile} />
          {renderAdminRoutes()}
        </Switch>

        {loading && (
          <div className="loader-cover">
            <div className="loader" />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ dashboard, user }) => ({
  loading: dashboard.loading,
  currentUser: user.currentUser,
});


export default withRouter(
  connect(mapStateToProps)(DashboardPage)
);
