import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Validate } from "../../utils/auth/validate";
import { setcurrentUser } from "../../redux/admin-user/admin.actions";
import { toaster, Spinner } from "evergreen-ui";

import "./login-page.styles.scss";
import logo from "../../assets/images/logo.svg";
import circle from "../../assets/images/circle.svg";
import marked from "../../assets/images/marked.svg";

import { LoginLayout } from "../../components/Login-layout/login-layout.component";
import Button from "../../components/custom-button/custom-button.component";
import FormInput from "../../components/form-input/form-input";
import FormPasswordInput from "../../components/form-password-input/form-password-input";
import { baseUrl } from "../../utils/global";

const LoginPage = ({ setcurrentUser }) => {
  const initialValues = {
    email: "",
    password: "",
  };

  const userOptions = ['Admin', 'Teachers']
  const [loginCredentials, setLoginCredentials] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userType, setUserType] = useState("");
  const [selectUserType, setSelectUserType] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const { email, password } = loginCredentials;

  const handleChange = (event) => {
    const { name, value } = event.target;

    setLoginCredentials({ ...loginCredentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    setFormErrors(Validate(loginCredentials));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }

    // eslint-disable-next-line
  }, [formErrors]);

  const submitForm = () => {
    fetch(`${baseUrl}/${selectUserType?'tutors':'admin'}/auth/login`, {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify(loginCredentials),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.message === "Successful") {
          setcurrentUser(data.data);
          localStorage.setItem("user", data.data.token);
          toaster.success("Sign in successful.");
        } else {
          toaster.danger(`${data.message}`);
          setFormErrors({
            email: "Please ensure that email is correct",
            password: "Please ensure that password is correct",
          });
        }
      });
  };

  return (
    <div className="auth-cover login">
      <div className="auth-background">
        <LoginLayout heading="Admin Dashboard" name="You've got access!" />
      </div>
      {userType ? <>
        <div className="auth-form-cover">
          <div className="auth-form">
            <form onSubmit={handleSubmit}>
              <h3 className="auth-form-heading">
                <span className="admin">{userOptions[selectUserType]} Login to</span>
                <span>
                  <img src={logo} alt="dyeka admin logo" />
                </span>
              </h3>
              <p className="auth-form-intro">
              </p>
              <FormInput
                name="email"
                onChange={handleChange}
                value={email}
                label="Email Address"
                type="email"
                errorText={formErrors.email ? `${formErrors.email}` : null}
                className={formErrors.email && "input-error"}
              />
              <FormPasswordInput
                name="password"
                onChange={handleChange}
                value={password}
                label="Password"
                errorText={formErrors.password ? `${formErrors.password}` : null}
                className={formErrors.password && "input-error"}
              />

              <p className="forgot-password">
                <Link to="/reset-password">Forgot password</Link>
              </p>

              <Button type="submit">
                {isLoading && <Spinner size={16} className="spinner" />}
                <span>LOGIN</span>
              </Button>
            </form>
          </div>
        </div>
      </> : <>
        <div className={`welcome auth-form-cover align-items-start`}>
          <h1>Welcome</h1>
          <div className="my-2">Select an account to login</div>
          <div className="w-100 d-flex justify-content-center align-items-center gap-4 my-5">
            <div className={`position-relative dashboard-card d-flex flex-column align-items-center justify-content-center sub-card border border-2 ${!selectUserType ? 'active' : ''}`} onClick={() => setSelectUserType(0)} >
              <img src={logo} alt="logo" />
              <p className="my-3">Admin Login</p>
              <p>Select to login</p>
              <img className="check" src={selectUserType ? circle : marked} alt="check" />
            </div>
            <div className={`position-relative dashboard-card d-flex flex-column align-items-center justify-content-center sub-card border border-2 ${selectUserType ? 'active' : ''}`} onClick={() => setSelectUserType(1)} >
              <img src={logo} alt="logo" />
              <p className="my-3">Teachers Login</p>
              <p>Select to login</p>
              <img className="check" src={!selectUserType ? circle : marked} alt="check" />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center"> <button className="btn btn-primary" onClick={() => setUserType(userOptions[selectUserType])}>Proceed</button></div>
        </div>
      </>}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setcurrentUser: (user) => dispatch(setcurrentUser(user)),
});

export default connect(null, mapDispatchToProps)(LoginPage);
