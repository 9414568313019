import React, {useEffect, useState} from "react";
import "./home.styles.scss";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import {connect} from "react-redux";

import DashboardStatContainer from '../../../components/general/dashboard-stat-card/dashboard-stat-card';
import DashboardPlanCard from '../../../components/general/dashboard-plan-card/dashboard-plan-card';
import DashboardTable from '../../../components/general/dashboard-table/dashboard-table';
import Api from "../../../utils/action";
import {generateItem, generateItemN} from "../../../utils/helper";
import {setLoading} from "../../../redux/dashboard/actions";
import Empty from "../../../components/general/empty/empty";

const Home = ({currentUser, setLoading}) => {
    const [mostViewedLessons, setMostViewedLessons] = useState([]);
    const [activeUsers, setActiveUsers] = useState(0)
    const [inActiveUsers, setInActiveUsers] = useState(0)
    const [totalUsers, setTotalUsers] = useState(0)
    const [selectedUserCount, setSelectedUserCount] = useState(0)
    const [totalLessons, setTotalLessons] = useState(0)
    const [totalVideos, setTotalVideos] = useState(0)
    const [plansStats, setPlanStats] = useState([])

    const [usersDropdownFilter, setUsersDropdownFilter] = useState({
        id: 'custom-select',
        title: 'Total',
        items: [],
    });

    const [mostViewedTable, setMostViewedTable] = useState();

    useEffect(() => {
        setLoading(true)

        Api.dashboard.stats(currentUser.token).then(data => {
            setActiveUsers(data.activeUsers)
            setInActiveUsers(data.inactiveUsers)
            setTotalUsers(data.totalUsers)
            setTotalVideos(data.videos)
            setTotalLessons(data.lessons)
            setLoading(false)
        })
    }, [currentUser.token])

    useEffect(() => setSelectedUserCount(totalUsers), [totalUsers])

    useEffect(() => {
        setUsersDropdownFilter({
            id: 'custom-select',
            title: 'Total',
            items: [
                generateItem('Total', 'total', '', '', '', true, () => setSelectedUserCount(totalUsers)),
                generateItem('Active', 'active', '', 'text-success', '', true, () => setSelectedUserCount(activeUsers)),
                generateItem('Inactive', 'inactive', '', 'text-danger', '', true, () => setSelectedUserCount(inActiveUsers)),
            ]
        })
    }, [activeUsers, inActiveUsers, totalUsers])

    useEffect(() => {
        async function getMostViewed() {
            setLoading(true)
            const res = await Api.lessons.mostViewed(currentUser.token)
            if (res.status) setMostViewedLessons(res.data)
            setLoading(false)
        }

        getMostViewed().then().catch(e => console.error(e.message))
    }, [currentUser.token])

    useEffect(() => {
        setMostViewedTable({
            columns: [
                {title: 'Subject', classes: 'no-wrap', key: 'subjectName'},
                {title: 'Class', classes: 'no-wrap', key: 'className'},
                {title: 'Lesson', classes: 'no-wrap', key: 'name'},
                {title: 'Views', classes: 'view-column no-wrap', key: 'views'},
            ],
            data: mostViewedLessons.map(mostView => {
                mostView.views = mostView.views ? mostView.views : 0
                mostView.subjectName = mostView.subjects.name
                mostView.className = mostView.class.name
                mostView.dropdown = {
                    id: `course-dropdown-${mostView.id}`,
                    title: 'Filter',
                    items: [
                      generateItemN({
                          text: 'View',
                          url: `/dashboard/classes/${mostView.class.id}/${mostView.subjects.id}/lesson/${mostView.id}/view`
                      })
                    ],
                }

                return mostView
            })
        })

        setLoading(false)
    }, [mostViewedLessons])

    useEffect(() => {
        async function fetchPlans() {
            setLoading(true)

            const res = await Api.plans.fetchPlansList(currentUser.token)
            if (res.status) {
                let boxCount = 4
                const data = res.data.splice(0, 3)

                data.forEach(plan => plan.class = `box${boxCount++}`)
                setPlanStats(data)
            }

            setLoading(false)
        }

        fetchPlans().then().catch(e => console.error(e.message))
    }, [currentUser.token])

    return (
      <div className="dashboard-home">
          <SectionNav header="Dashboard"/>

          <div className="dashboard-grid">

              <DashboardStatContainer
                className="box1"
                icon="user-ic-orange.svg"
                title="NUMBER OF USERS"
                value={selectedUserCount}
                hasSelect={true}
                dropDown={usersDropdownFilter}
              />

              <DashboardStatContainer
                className="box2"
                icon="courses-ic-orange.svg"
                title="TOTAL NUMBER OF LESSONS"
                value={totalLessons}
              />

              <DashboardStatContainer
                className="box3"
                icon="videos-ic-orange.svg"
                title="NUMBER OF VIDEOS"
                value={totalVideos}
              />

              {
                  plansStats.map(planStat => (
                    <DashboardPlanCard
                      key={planStat.name}
                      className={planStat.class}
                      title={`${planStat.name.toUpperCase()} PLAN`}
                      value={planStat.count}
                    />
                  ))
              }

              {
                  plansStats.length <= 0 &&
                  <Empty message="There are no subscription plans" />
              }

              <div className="dashboard-tab-view dashboard-card box7">
                  <div className="dashboard-tab-heading">
                      <p className="table-title">MOST VIEWED LESSONS</p>
                  </div>
                  <div className="dashboard-tab-body">
                      {
                          mostViewedTable &&
                          <DashboardTable
                            columns={mostViewedTable.columns}
                            data={mostViewedTable.data}
                            hasMenu={true}
                          />
                      }
                  </div>
              </div>
          </div>
      </div>
    );
};

const mapStateToProps = ({user}) => ({
    currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    setLoading: (loading) => dispatch(setLoading(loading))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
