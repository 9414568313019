import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {useQuery} from "react-query";
import {connect} from "react-redux";
import "./setting.styles.scss";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import Paginate from "../../../components/paginate/paginate";
import DashboardTable from "../../../components/general/dashboard-table/dashboard-table";
import CustomSelect from "../../../components/general/custom-select/custom-select";
import {generateItem, generateItemN, generateTableCol} from "../../../utils/helper";
import RolesApi from "../../../utils/action/role";
import PermissionsApi from "../../../utils/action/permissions";
import RoleModal from "../../../components/modals/role-modal";
import TeammatesApi from "../../../utils/action/teammates";
import Api from "../../../utils/action";
import { Modal } from "bootstrap"
import TeammateModal from "../../../components/modals/teammate-modal";
import {formatCurrency, perPage} from "../../../utils/global";
import {setLoading} from "../../../redux/dashboard/actions";
import {toaster} from "evergreen-ui";
import TeammatesFilter from "../../../components/settings/teammates-filter";

const Settings = ({ currentUser, setLoading }) => {
  const [previewSettings, switchPreviewSettings] = useState("Teams");
  const [roleNameFilter, setRoleNameFilter] = React.useState(null);

  // Initialize roles, rolesTable, and roseSelect
  const [roles, setRoles] = useState([])
  const [rolesSelect, setRolesSelect] = useState(null)
  const [rolesTable, setRolesTable] = useState(null)
  const [roleModal, setRoleModal] = useState(null)

  const [teammates, setTeammates] = useState([])
  const [teammatesTable, setTeammatesTable] = useState(null)
  const [teammateModal, setTeammateModal] = useState(null)
  const [teammatesFilters, setTeammatesFilters] = useState({
    role_name: '', first_name: '', last_name: '', email: ''
  })
  const [teammatesPagination, setTeammatesPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  })

  const [selectedRole, setSelectedRole] = useState({ name: '', description: '', permissions: [] })
  const [selectedTeammate, setSelectedTeammate] = useState({ email: '', role_id: 0 })

  const fetchRoles = async () => {
    const res = await RolesApi.fetchRoles(currentUser.token)
    if (res.status) setRoles(res.data)
  }

  const fetchTeammates = async (reset = false) => {
    setLoading(true)

    const page = reset ? 0 : teammatesPagination.page
    const res = await Api.teammates.fetchTeammates(currentUser.token, page, teammatesFilters)
    if (res.status) {
      setTeammates(res.data.result)
      setTeammatesPagination(pagination => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
        page,
      }))
    }

    setLoading(false)
  }

  const setTeammatesPage = (page) => {
    setTeammatesPagination(pagination => ({ ...pagination, page: page }))
  }

  const addTeammate = () => {
    setSelectedTeammate({ email: '', role_id: 0 })
    teammateModal.show()
  }

  const editTeammate = (teammate) => {
    setSelectedTeammate(teammate)
    teammateModal.show()
  }

  const saveTeammate = async (res) => {
    if (res && res.status) {
      teammateModal.hide()
      await fetchTeammates()
    }
  }

  const updateTeammateStatus = (id, status) => {
    setLoading(true)
    Api.teammates.updateTeammateStatus(id, status, currentUser.token, async () => {
      await fetchTeammates()
    })
  }

  const reInviteTeammate = (teammate) => {
    const data = {
      email: teammate.email,
      role_id: teammate.role_id
    }

    Api.teammates.addTeammate(currentUser.token, data, async (res) => {
      if (res.status) await fetchTeammates()
    })
  }

  const deleteTeammate = (id) => {
    TeammatesApi.deleteTeammate(id, currentUser.token, async () => {
      await fetchTeammates()
    })
  }

  const addRole = () => {
    setSelectedRole({ name: '', description: '', permissions: [] })
    roleModal.show()
  }

  const editRole = (role) => {
    setSelectedRole({ ...role, permissions: role.permissions.map(p => p.id) })
    roleModal.show()
  }

  const saveRole = async (res) => {
    if (res && res.status) {
      roleModal.hide()
      await fetchRoles()
    }
  }

  const deleteRole = (id) => {
    RolesApi.deleteRole(id, currentUser.token, async () => {
      toaster.success("Role deleted.")
      await fetchRoles()
    })
  }

  const closeModal = (modal) => modal.hide()

  useEffect(() => {
    const teammateModalElem = document.querySelector('.teammate-modal')
    const roleModalElem = document.querySelector('.role-modal')

    if (teammateModalElem) setTeammateModal(new Modal(teammateModalElem))
    if (roleModalElem) setRoleModal(new Modal(roleModalElem))
  }, [])

  useEffect(() => {
    fetchRoles().then().catch(e => console.error(e))
  }, [currentUser.token])

  useEffect(() => {
    fetchTeammates().then().catch(e => console.error(e))
  }, [currentUser.token, teammatesPagination.page])

  // Teammates table
  useEffect(() => {
    const teammatesTable = {
      id: 'teammates-table',
      cols: [
        generateTableCol('Name', 'full_name'),
        generateTableCol('Email', 'email'),
        generateTableCol('Role', 'role_name'),
      ],
      data: teammates.map(teammate => {
        teammate.role_name = teammate.role.name

        const items = []

        const deactivate = generateItemN({
          text: 'Deactivate',
          value: 'deactivate',
          classes: 'text-warning',
          hasAction: true,
          action: () => updateTeammateStatus(teammate.id, false)
        })

        const activate = generateItemN({
          text: 'Activate',
          value: 'activate',
          classes: 'text-success',
          hasAction: true,
          action: () => updateTeammateStatus(teammate.id, true)
        })

        const del = generateItemN({
          text: 'Delete',
          value: 'delete',
          classes: 'text-danger',
          hasAction: true,
          action: () => deleteTeammate(teammate.id)
        })

        const reInvite = generateItemN({
          text: 'Re-invite',
          value: 'reInvite',
          classes: 'text-success',
          hasAction: true,
          action: () => reInviteTeammate(teammate)
        })

        if (teammate.activated && teammate.first_name && teammate.last_name) items.push(deactivate)
        else if (!teammate.activated && !teammate.first_nam && !teammate.last_name) items.push(reInvite)
        else items.push(activate)
        items.push(del)

        teammate.full_name = `${teammate.first_name ? teammate.first_name: ''} ${teammate.last_name ? teammate.last_name : ''}`
        teammate.dropdown = {
          id: `teammate-${teammate.id}-menu`,
          title: '',
          items: [
            generateItemN({
              text: 'Edit',
              value: 'edit',
              hasAction: true,
              action: () => editTeammate(teammate)
            }),
            ...items
          ],
        }

        return teammate
      })
    }

    setTeammatesTable(teammatesTable)
  }, [teammates])

  // Role dropdown
  useEffect(() => {
    const rolesSelect = {
      id: 'roles-select',
      title: 'All',
      items: [
        {
          text: 'All',
          value: 'all',
          id: '',
          classes: '',
          hasAction: true,
          action: () => setRoleNameFilter(null)
        },
      ],
    }
    roles.forEach(role => {
      rolesSelect.items.push(generateItem(
          role.name,
          role.slug,
          '',
          '',
          '',
          true,
          () => setRoleNameFilter(role.name)
      ))
    })

    setRolesSelect(rolesSelect)
  }, [roles])

  // Roles table
  useEffect(() => {
    const rolesTable = {
      id: 'roles-table',
      cols: [
        generateTableCol('Role Name', 'name', 'no-wrap'),
        generateTableCol('Description', 'description', 'no-wrap'),
        generateTableCol('Permission', 'permissions_slug'),
      ],
      data: roles.map(role => {
        role.permissions_slug = [...role.permissions.map(p => p.name)].join(', ')
        role.dropdown = {
          id: `role-${role.id}-menu`,
          title: '',
          items: [
            generateItem('Edit', 'edit', '', '', '', true, () => editRole(role)),
            generateItem('Delete', 'delete', '', 'text-danger', '', true, () => deleteRole(role.id)),
          ],
        }

        return role
      })
    }

    setRolesTable(rolesTable)
  }, [roles])


  // Fetch and set Permissions
  const [permissions, setPermissions] = useState([])
  useEffect(() => {
    PermissionsApi.fetchPermissions(currentUser.token, (res) => {
      if (res.status) setPermissions(res.data)
    })
  }, [currentUser.token])
  // End Fetch and set Permissions

  return (
      <div className="dashboard-settings-page">
        <SectionNav header="Settings" />

        <div className="d-flex">
          {previewSettings === "Teams" ? (
              <div className="add-btn ms-auto" onClick={addTeammate}>
                <span className="btn-txt">ADD A TEAMMATE</span>
                <span className="btn-plus">+</span>
              </div>
          ): (
              <div className="add-btn ms-auto" onClick={addRole}>
                <span className="btn-txt">ADD A ROLE</span>
                <span className="btn-plus">+</span>
              </div>
          )}
        </div>

        <div className="dashboard-card dashboard-tab-view">
          <div className="dashboard-tab-heading">
            <div className="dashboard-tab-menu">
              <span
                  onClick={() => switchPreviewSettings("Teams")}
                  className={`tab-menu-item${previewSettings === "Teams" ? " active" : ""}`}
              >
                TEAMS
              </span>
              <span
                  onClick={() => switchPreviewSettings("Roles")}
                  className={`tab-menu-item${previewSettings === "Roles" ? " active" : ""}`}
              >
                ROLES AND PERMISSIONS
              </span>
            </div>
          </div>
          <div className="dashboard-tab-body">
            {previewSettings === "Teams" ? (
                <div className="teams-content">
                  <div className="col-md-5 filter-cover">
                    <TeammatesFilter
                      roles={roles}
                      filters={teammatesFilters}
                      setFilters={setTeammatesFilters}
                      filterRes={fetchTeammates}
                    />
                  </div>

                  {
                    teammatesTable &&
                    <DashboardTable
                        tableId="teams"
                        columns={teammatesTable.cols}
                        data={teammatesTable.data}
                        hasMenu={true}
                    />
                  }

                  <Paginate
                    currentPage={teammatesPagination.page}
                    totalPages={teammatesPagination.total}
                    setPage={setTeammatesPage}
                  />
                </div>
            ) : (
                <div className="roles-content">
                  {
                    rolesTable &&
                    <DashboardTable
                        tableId="roles"
                        columns={rolesTable.cols}
                        data={rolesTable.data}
                        hasMenu={true}
                    />
                  }
                </div>
            )}
            {/* </table> */}
          </div>
        </div>

        <TeammateModal
            auth={currentUser.token}
            closeModal={() => closeModal(teammateModal)}
            roles={roles}
            saveTeammateCb={saveTeammate}
            teammate={selectedTeammate}
            updateTeammate={(teammate) => setSelectedTeammate({...teammate})}
        />

        <RoleModal
            auth={currentUser.token}
            closeModal={() => closeModal(roleModal)}
            permissions={permissions}
            addRoleCb={saveRole}
            role={selectedRole}
            updateRole={(role) => setSelectedRole({...role})}
        />
      </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
