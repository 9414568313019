import { connect } from "react-redux";
import { toaster } from "evergreen-ui";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash-ic.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-ic.svg";
import LessonVideoModal from "../../modals/lesson-video-modal";
import { Modal } from "bootstrap";
import React, { useState, useEffect } from "react";
import produce from "immer";

const LessonFiles = ({ auth, readOnly, files, updateFiles, type, accepts }) => {
  const [lessonVideoModal, setLessonVideoModal] = useState(null);
  const [lessonDocModal, setLessonDocModal] = useState(null);

  const addFile = (vimeoVideo, e) => {
    if (readOnly) return;

    let video;
    if (e) {
      if (e.target.files.length < 1) {
        toaster.danger("Please Select a file");
        return;
      }
      const file = e.target.files[0];
      video = { file, title: file.name, type };
    } else {
      video = {
        file: null,
        title: vimeoVideo.title,
        type,
        number: vimeoVideo.videoNumber,
      };
    }

    updateFiles(
      produce(files, (data) => {
        data.push(video);
      })
    );

    if (type === "documents") lessonDocModal.hide();
    else lessonVideoModal.hide();
  };

  const updateFile = (id, key, value) => {
    updateFiles(
      produce(files, (data) => {
        data[id] = { ...data[id], [key]: value };
      })
    );
  };

  const setFileTitle = (e, id, file) => {
    if (readOnly) return;
    updateFile(id, "title", e.target.value);
  };

  const deleteFile = (id) => {
    if (readOnly) return;

    if (files[id].id) updateFile(id, "delete", true);
    else {
      updateFiles(
        produce(files, (data) => {
          data.splice(id, 1);
        })
      );
    }
  };

  const toggleEdit = (id) => {
    updateFile(id, "edit", !files[id].edit);
  };

  const showAddLessonModal = (type) => {
    if (type === "videos") lessonVideoModal.show();
    else lessonDocModal.show();
  };

  const closeModal = (type) => {
    if (type === "videos") lessonVideoModal.hide();
    else lessonDocModal.hide();
  };

  useEffect(() => {
    const lessonVideoModalElem = document.querySelectorAll(".lesson-video-modal");

    if (lessonVideoModalElem.length > 0) {
      if (lessonVideoModalElem[0]) setLessonVideoModal(new Modal(lessonVideoModalElem[0]));
      if (lessonVideoModalElem[1]) setLessonDocModal(new Modal(lessonVideoModalElem[1]));
    }
  }, []);

  return (
    <div className="files-cover">
      <div className="files">
        {files.map(
          (file, id) =>
            !file.delete && (
              <div className="file" key={`file-${id}`}>
                <div className="d-flex align-items-center">
                  <div className="name-cover me-auto flex-shrink-1">
                    {!file.edit && <p className="name">{file.title}</p>}
                    {file.edit && <input className="name dashboard-input" value={file.title} onChange={(e) => setFileTitle(e, id, file, "videos")} />}
                  </div>
                  <div className="d-flex align-items-center flex-shrink-0">
                    {!readOnly && (
                      <div className="action-btn flex-shrink-0 d-flex align-items-center">
                        <div className="mx-2" onClick={() => deleteFile(id)}>
                          <DeleteIcon />
                          <span className="ms-1">Remove</span>
                        </div>
                        <div className="mx-2" onClick={() => toggleEdit(id)}>
                          <EditIcon />
                          <span className="ms-1">{file.edit ? "Done" : "Edit"}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
        )}
      </div>
      {!readOnly && (
        <div className="d-flex">
          <label className="ms-auto" onClick={() => showAddLessonModal(type)}>
            <span className="button_round">+</span>
          </label>
        </div>
      )}
      <LessonVideoModal auth={auth} closeModal={() => closeModal(type)} addFile={addFile} type={type} accepts={accepts} />
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  auth: user.currentUser.token,
});

export default connect(mapStateToProps)(LessonFiles);
