import "./save-plan.styles.scss";
import { TopSectionNav } from "../../../../components/top-section-nav/top-section-nav";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {toaster} from "evergreen-ui";
import {useHistory, useParams} from "react-router-dom";
import SavePlanForm from "../../../../components/subscription/save-plan-form";
import {setLoading} from "../../../../redux/dashboard/actions";
import Api from "../../../../utils/action";

const EditPlan = ({ auth, setLoading }) => {
  const {id} = useParams()
  const history = useHistory()
  const [plan, setPlan] = useState({
    name: '',
    profiles: 0,
    devices: 0,
    monthly_amount: 0,
    yearly_amount: 0,
    description: '',
  })

  const fetchPlan = async () => {
    setLoading(true)

    try {
      const res = await Api.plans.fetchPlan(auth, id)
      if (res.status) {
        setPlan(res.data)
      }
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  const savedPlan = (message) => {
    toaster.success("Plan Updated");
    history.push('/dashboard/subscriptions')
  }

  useEffect(() => {
    fetchPlan().then().catch(e => console.error(e))
  }, [id])

  return (

    <div className="save-plan">
      <TopSectionNav
        path="/dashboard/subscriptions"
        header="Subscription"
        back="Subscriptions"
      />

      <SavePlanForm
        plan={plan}
        setPlan={setPlan}
        savedPlan={savedPlan}
      />
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  auth: user.currentUser.token,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditPlan);
