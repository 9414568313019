import ApiCalls from "./api";

const Lessons = {
  mostViewed: (auth) => ApiCalls.getFetchListAsync(auth, "admin/dashboard/lessons/viewed", []),
  fetch: async (auth, page, classId = null, subjectId = null, term = null) => {
    const filters = [];
    if (classId) filters.push(["class_id", classId]);
    if (subjectId) filters.push(["subject_id", subjectId]);
    if (term) filters.push(["term", term]);

    return await ApiCalls.getForQuery(auth, "admin/lessons", page, filters);
  },

  fetchById: async (auth, id) => await ApiCalls.getById(auth, "admin/lessons", id),
  create: async (auth, data) => await ApiCalls.postForResAsync(auth, "admin/lessons", data),
  update: async (auth, id, data) => await ApiCalls.patchForResAsync(auth, `admin/lessons/${id}`, data),
  delete: async (auth, id) => await ApiCalls.deleteReqAsync(auth, `admin/lessons`, id),
  updateAction: async (auth, id, data) => await ApiCalls.putForRes(auth, `admin/lessons/${id}/action`, data),
};
export default Lessons;
