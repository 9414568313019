import React, { useEffect, useState } from "react";
import "./add-lesson.scss";
import { TopSectionNav } from "../../../../../../components/top-section-nav/top-section-nav";
import { toaster } from "evergreen-ui";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SaveLessonForm from "../../../../../../components/lesson/save-lesson-form/save-lesson-form";
import { produce } from "immer";
import Api from "../../../../../../utils/action";

const AddLesson = ({ auth }) => {
  const history = useHistory();
  const { classId, subjectId } = useParams();
  const [lesson, setLesson] = useState({
    class_id: classId,
    topic: "",
    description: "",
    tutor_name: "",
    tutor_bio: "",
    thumbnail: "",
    downloadable: false,
    term: "first",
    subject: "",
    tags: [],
    preview_duration: 0,
    videos: [],
    documents: [],
  });

  const fetchSubject = async () => {
    const res = await Api.classes.fetchSubjectById(auth, subjectId);
    if (res.status) updateLesson("subject", res.data.name);
  };

  const updateLesson = (key, val) => {
    setLesson((lesson) => {
      return produce(lesson, (data) => {
        data[key] = val;
      });
    });
  };

  const savedLesson = (message) => {
    toaster.success("Lesson Created successfully");
    history.push(`/dashboard/classes/${classId}/${subjectId}`);
  };

  useEffect(() => {
    fetchSubject()
      .then(() => {})
      .catch((e) => console.error(e));
  }, [auth, subjectId]);

  return (
    <div className="add-lesson-page">
      <TopSectionNav
        path={`/dashboard/classes/${classId}/${subjectId}`}
        header="Lesson"
        back={`Back to ${lesson.subject}`}
      />

      <h1>ADD COURSE MODULE</h1>

      <SaveLessonForm
        auth={auth}
        lesson={lesson}
        updateLesson={updateLesson}
        classId={classId}
        savedLesson={savedLesson}
      />
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  auth: user.currentUser.token,
});

export default withRouter(connect(mapStateToProps)(AddLesson));
