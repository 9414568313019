import { CustomFileInput, CustomInput, CustomSelectInput, CustomTextarea } from "../../form-elements/custom-input";
import { Spinner, TagInput, toaster } from "evergreen-ui";
import React, { useEffect, useMemo, useState } from "react";
import ApiCalls from "../../../utils/action/api";
import Api from "../../../utils/action";
import { generateItemN } from "../../../utils/helper";
import { tutorDropdown } from "../../../utils/helper";

const SaveLivestreamForm = ({ auth, livestream, updateLivestream, savedLivestream }) => {

  const [classes, setClasses] = useState([])
  const [classesDropdown, setClassesDropdown] = useState(null)
  const [subjects, setSubjects] = useState([])
  const [subjectsDropdown, setSubjectsDropdown] = useState(null)
  const [loading, setLoading] = useState(false);
  const [tutors, setTutors] = useState([])

  useEffect(() => {
    async function fetchClasses() {
      const res = await Api.classes.fetchClassesList(auth)
      if (res.status) await setClasses(res.data.result)
    }

    fetchClasses().then().catch((e) => console.error(e))
  }, [auth])

  useEffect(() => {
    if (classes) {
      const classesDropdown = {
        id: 'livestream-classes',
        items: classes.map(
          cl => generateItemN({
            text: cl.name,
            value: cl.id,
            hasAction: true,
            action: () => updateLivestream('class_id', cl.id)
          })
        )
      }

      setClassesDropdown(classesDropdown)
    }
  }, [classes])

  useEffect(() => {
    async function fetchSubjects() {
      const res = await Api.classes.fetchSubjects(auth)
      if (res.status) await setSubjects(res.data.result)
    }

    fetchSubjects().then().catch((e) => console.error(e))
  }, [auth, livestream.class_id])

  useEffect(() => {
    async function fetchTutors() {
      const res = await Api.tutors.fetchTutors(auth);
      if (res.status) setTutors(res.data);
    }

    fetchTutors()
      .then(() => { })
      .catch((e) => console.log(e));
  }, [auth, livestream.class_id]);

  useEffect(() => {
    if (subjects) {
      const subjectsDropdown = {
        id: 'livestream-subjects',
        items: subjects.map(
          subject => generateItemN({
            text: subject.name,
            value: subject.name,
            hasAction: true,
            action: () => updateLivestream('subject', subject.name)
          })
        )
      }

      setSubjectsDropdown(subjectsDropdown)
    }
  }, [livestream.class_id, subjects, updateLivestream])

  const getClassName = (id) => {
    const found = classes.find(cl => cl.id === id)
    if (found) return found.name
    else return ''
  }

  const previewImage = (e, key) => {
    let reader = new FileReader();
    const file = (e && e.target.files.length > 0) ? e.target.files[0] : null

    if (file) {
      reader.onload = function () {
        updateLivestream(key, reader.result)
        updateLivestream(`${key}_file`, file)
      }

      reader.readAsDataURL(file)
    }
  }

  const uploadImages = async (image, url = '') => {
    if (image) {
      const imageData = new FormData()
      imageData.append('file', image, image.name)
      const imageRes = await ApiCalls.uploadFile(auth, 'images', imageData)
      if (imageRes.status) return imageRes.data.url
    } else {
      if (url.startsWith('http')) return url
      else throw new Error('Error uploading image')
    }
  }

  const eventTypeDropdown = useMemo(() => {
    const eventOptions = [{ name: 'general' }, { name: "one-on-one" }]
    return {
      id: 'livestream-event_type',
      items: eventOptions.map(
        item => generateItemN({
          text: item.name,
          value: item.name,
          hasAction: true,
          action: () => updateLivestream('event_type', item.name)
        })
      )
    }
  }, [updateLivestream])

  const saveStream = async () => {
    setLoading(true)

    const data = {
      title: livestream.title,
      class_id: livestream.class_id,
      description: livestream.description,
      tutor_id: livestream.tutor_id || 1,
      price: livestream.price,
      tags: livestream.tags,
      event_type: livestream.event_type,
      subject: livestream.subject,
      event_date: new Date(livestream.event_date),
      duration: livestream.duration,
      thumbnail: livestream.thumbnail,
      content_type: "curriculum"
    }

    try {
      data.thumbnail = await uploadImages(livestream.thumbnail_file, data.thumbnail)
    } catch (e) {
      toaster.danger('Unable to upload image')
      setLoading(false)
      return
    }

    const res = livestream.id ?
      await Api.livestreams.update(auth, livestream.id, data) :
      await Api.livestreams.create(auth, data)

    setLoading(false)
    if (res.status) savedLivestream(res)
    else toaster.danger(res.message)
  }

  return (
    <form className="add-livestream-form">

      <CustomInput
        id="title"
        name="title"
        type="text"
        label="Title"
        value={livestream.title}
        handleChange={(e) => updateLivestream('title', e.target.value)}
      />

      {
        classesDropdown &&
        <CustomSelectInput
          id="classes"
          name="class"
          dropDown={classesDropdown}
          label="Class"
          selected={{ value: livestream.class_id, text: getClassName(livestream.class_id) }}
        />
      }

      <CustomTextarea
        id="description"
        name="description"
        type="text"
        placeholder="description"
        label="Description"
        value={livestream.description}
        handleChange={(e) => updateLivestream('description', e.target.value)}
      />

      <CustomInput
        id="time"
        name="time"
        type="datetime-local"
        label="Event Date"
        value={livestream.event_date}
        handleChange={(e) => updateLivestream('event_date', e.target.value)}
      />

      <CustomInput
        id="duration"
        name="duration"
        type="number"
        placeholder="Duration in minutes"
        label="Duration (Minutes)"
        value={livestream.duration}
        handleChange={(e) => updateLivestream('duration', e.target.value)}
      />

      <CustomSelectInput
        id="tutor"
        name="tutor_id"
        dropDown={tutorDropdown(tutors)}
        label="Tutor"
      // selected={{ text: `${tutors[lesson.tutor_id]?.first_name} ${tutors[lesson.tutor_id]?.last_name}`, value: lesson.tutor_id }}
      />

      <CustomTextarea
        id="price"
        name="price"
        type="number"
        placeholder="price"
        label="Price"
        value={livestream.price}
        handleChange={(e) => updateLivestream('price', e.target.value)}
      />

      <CustomFileInput
        id="stream-thumbnail"
        label="Upload Live Stream Thumbnail"
        src={livestream.thumbnail}
        accept="image/png,image/jpg,image/jpeg,.svg"
        handleChange={(e) => previewImage(e, 'thumbnail')}
      />
      {eventTypeDropdown && <CustomSelectInput
        id="event_type"
        name="event_type"
        label="Event type"
        dropDown={eventTypeDropdown}
        selected={{ value: livestream.event_type, text: livestream.event_type }}
      />}
      {
        subjectsDropdown &&
        <CustomSelectInput
          id="subject"
          name="subject"
          label="Subject"
          dropDown={subjectsDropdown}
          selected={{ value: livestream.subject, text: livestream.subject }}
        />
      }

      <div id="tag-cover" className="form-group">
        <label>Tags/Keywords</label>
        <TagInput
          tagProps={{
            color: "neutral",
            height: "30px",
            display: "flex",
            justifyContent: "space-between",
            fontSize: "17px",
            fontFamily: "Avenir",
            paddingX: "10px",
          }}

          marginTop="1rem"
          backgroundColor="#fff"
          height={40}
          width="100%"
          borderRadius={7}
          border="none"
          outline="none"
          inputProps={{
            placeholder: "Add tag...",
            color: "#ff6c00",
            fontSize: "17px",
          }}
          values={livestream.tags}
          onChange={(values) => {
            updateLivestream('tags', values)
          }}
        />
      </div>

      <div id="publish-cover" className="publish">
        <div className="btn-cover">
          <button className="btn-primary" type="button" onClick={() => saveStream()}>
            {loading && <Spinner size={16} className="spinner" />}
            <span>{livestream.id ? 'EDIT' : 'CREATE'}</span>
          </button>
        </div>
      </div>
    </form>
  )
}

export default SaveLivestreamForm