import { CustomInput } from "../form-elements/custom-input";
import React, { useEffect, useState } from "react";
import { Spinner, toaster } from "evergreen-ui";

const LessonVideoModal = ({ closeModal, addFile, type, accepts }) => {
  const [actionRunning, setActionRunning] = useState(false);
  const [hasVideoNumber, setHasVideoNumber] = useState(false);
  const [vimeoNumberVideo, setVimeoNumberVideo] = useState({
    title: "",
    videoNumber: "",
  });
  const updateInput = (e) => {
    if (e.target.value === "" || !e.target.value) {
      toaster.danger("Please completely fill out the form");
    }
    vimeoNumberVideo[e.target.name] = e.target.value;
    setVimeoNumberVideo(vimeoNumberVideo);
  };

  const save = (e) => {
    e.preventDefault();
    if (e.target.files) {
      //handle upload
      addFile(null, e);
    } else {
      //handle video number
      if (vimeoNumberVideo.title === "" || !vimeoNumberVideo.title || vimeoNumberVideo.videoNumber === "" || !vimeoNumberVideo.videoNumber) {
        toaster.danger("Please completely fill out the form");
        return;
      }
      addFile(vimeoNumberVideo, null);
    }
  };

  useEffect(() => {
    setHasVideoNumber(false);
  }, []);

  const backToUploadOption = () => {
    setHasVideoNumber(false);
  };

  return (
    <div className="modal settings-modal lesson-video-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">{type === "videos" ? "ADD VIDEO" : "ADD DOCUMENT"}</p>
            <div className="close-btn" onClick={closeModal}>
              <span>X</span>
            </div>
          </div>
          <div className="modal-body">
            {!hasVideoNumber && (
              <div className="d-flex justify-content-center">
                {/* {type === "videos" && (
                  <button className="btn-primary me-5" onClick={() => setHasVideoNumber(true)}>
                    ADD AN EXISTING VIMEO VIDEO
                  </button>
                )} */}
                <label className="btn-primary btn">
                  {type === "videos" ? "UPLOAD VIDEO FROM DEVICE" : "UPLOAD DOCUMENT FROM DEVICE"}
                  <input type="file" accept={accepts} id="video" className="lesson-video d-none" onChange={save} />
                </label>
              </div>
            )}
            {hasVideoNumber && (
              <div>
                <CustomInput id="class-name" label="Title" name="title" placeholder="Video Title" handleChange={updateInput} />
                <CustomInput id="class-name" label="Video Number" name="videoNumber" placeholder="Video Number" handleChange={updateInput} />
              </div>
            )}
          </div>
          <div className="modal-footer">
            {!actionRunning ? (
              <div className="row">
                {hasVideoNumber && (
                  <React.Fragment>
                    <div className="col-sm-6">
                      <button className="btn-primary submit-btn" onClick={backToUploadOption}>
                        BACK
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <button className="btn-primary submit-btn" onClick={save}>
                        ADD
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonVideoModal;
