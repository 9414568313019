import "./save-lesson-form.scss";
import { CustomFileInput, CustomInput, CustomSelectInput, CustomTextarea } from "../../form-elements/custom-input";
import { termDropdown, tutorDropdown, contentDropdown } from "../../../utils/helper";
import { Spinner, TagInput, toaster } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import ApiCalls from "../../../utils/action/api";
import Api from "../../../utils/action";
import { useHistory } from "react-router-dom";
import { setLoading } from "../../../redux/dashboard/actions";
import { connect } from "react-redux";
import LessonFiles from "../lesson-videos/lesson-files";
import { addToFileQueue } from "../../../redux/file-upload-queue/actions";

const SaveLessonForm = ({ auth, lesson, updateLesson, classId, savedLesson, loading, setLoading, addToFileQueue, readOnly = false }) => {
  const history = useHistory();
  const [newVideos, setNewVideos] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [classDetails, setClassDetails] = useState(null);
  const [tutors, setTutors] = useState([]);
  const [allTutors, setAttTutors] = useState(tutors);
  const [terms, setTerms] = useState({
    first: "First Term",
    second: "Second Term",
    third: "Third Term",
  });
  const [contentTypes, setContentTypes] = useState({
    curriculum: "Curriculum",
    "non-curriculum": "Non-Curriculum",
  });
  const [errors, setErrors] = useState({
    topic: null,
    term: null,
    description: null,
    downloadable: null,
    previewDuration: null,
    tags: null,
    videos: null,
    documents: null,
    price: null,
    tutor_id: null,
    content_type: null,
  });

  useEffect(() => {
    async function fetchClass() {
      const res = await Api.classes.fetchClass(auth, classId);
      if (res.status) setClassDetails(res.data);
    }

    fetchClass()
      .then(() => {})
      .catch((e) => console.log(e));
  }, [auth, classId]);

  useEffect(() => {
    async function fetchTutors() {
      const res = await Api.tutors.fetchTutors(auth);
      if (res.status) setTutors(res.data);
    }

    fetchTutors()
      .then(() => {})
      .catch((e) => console.log(e));
  }, [auth, classId]);

  const setTopic = (e) => updateLesson("topic", e.target.value);
  const setTerm = (term) => updateLesson("term", term.value);
  const setContentType = (content_type) => updateLesson("content_type", content_type.value);
  const setDescription = (e) => updateLesson("description", e.target.value);
  const setTutorId = (tutor) => updateLesson("tutor_id", tutor.value);
  const setPrice = (e) => updateLesson("price", e.target.value);
  const setDownloadable = (e) => updateLesson("downloadable", e.target.checked);
  const setPreviewDuration = (e) => updateLesson("preview_duration", e.target.value);
  const setTags = (tags) => updateLesson("tags", tags);

  const validateForm = () => {};

  const previewImage = (e, key) => {
    let reader = new FileReader();
    const file = e && e.target.files.length > 0 ? e.target.files[0] : null;

    if (file) {
      reader.onload = function () {
        updateLesson(key, reader.result);
        updateLesson(`${key}_file`, file);
      };

      reader.readAsDataURL(file);
    }
  };

  const uploadImages = async (image, url = "") => {
    if (image) {
      const imageData = new FormData();
      imageData.append("file", image, image.name);
      const imageRes = await ApiCalls.uploadFile(auth, "images", imageData);
      if (imageRes.status) return imageRes.data.url;
    } else {
      if (url.startsWith("http")) return url;
      else throw new Error("Error uploading image");
    }
  };

  const uploadFiles = async (type, files) => {
    if (files) {
      const filePath = `/${type}`;
      const formData = new FormData();
      for (const file of files) {
        formData.append("files", file);
      }
      formData.append("path", filePath);
      const fileRes = await ApiCalls.uploadFiles(formData);
      if (fileRes.status) return fileRes.data;
    } else {
      throw new Error("Error uploading file");
    }
  };

  const submitForm = async (draft = false) => {
    setLoading(true);

    if (lesson.videos.length < 1) {
      toaster.danger("Upload videos to continue");
      setLoading(false);
      return;
    }

    const newVideos = lesson.videos.filter((video) => !video.id);
    const oldVideos = lesson.videos.filter((video) => video.id);

    const newDocuments = lesson.documents.filter((doc) => !doc.id);
    const oldDocuments = lesson.documents.filter((doc) => doc.id);

    const data = {
      class_id: lesson.class_id,
      topic: lesson.topic,
      description: lesson.description,
      downloadable: lesson.downloadable,
      tutor_id: lesson.tutor_id,
      content_type: lesson?.content_type,
      thumbnail: lesson.thumbnail,
      term: lesson.term,
      subject: lesson.subject,
      tags: lesson.tags,
      price: lesson.price,
      preview_duration: lesson.preview_duration,
      draft,
    };

    const videoFiles = [];
    const documentFiles = [];

    for (const video of newVideos) {
      videoFiles.push(video.file);
    }

    for (const document of newDocuments) {
      documentFiles.push(document.file);
    }

    if (data.thumbnail && data.thumbnail !== "") {
      data.thumbnail = await uploadImages(lesson.thumbnail_file, data.thumbnail);
    }

    data.videos = oldVideos.map((video) => {
      return {
        id: video.id,
        url: video.url,
        title: video.title,
        number: video.number,
        delete: video.delete,
      };
    });
    if (videoFiles.length > 0) {
      data.videos = [...data.videos, ...(await uploadFiles("videos", videoFiles))];
    }

    data.documents = oldDocuments.map((doc) => {
      return { id: doc.id, url: doc.url, name: doc.title, delete: doc.delete };
    });

    if (documentFiles.length > 0) {
      data.documents = [...data.documents, ...(await uploadFiles("documents", documentFiles))];
    }

    try {
      const res = lesson.id ? await Api.lessons.update(auth, lesson.id, data) : await Api.lessons.create(auth, data);

      if (res.status) {
        savedLesson(res);
      } else toaster.danger(res.message);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const lessonAction = async (action) => {
    setLoading(true);
    try {
      const data = {
        action,
      };
      const res = await Api.lessons.updateAction(auth, lesson.id, data);
      if (res.message === "Success") {
        toaster.success(res.message);
        savedLesson(res);
      } else toaster.danger(res.message);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    lesson && (
      <form className="add-lesson-form save-lesson-form row">
        <div className="col-md-6 mt-3">
          <CustomFileInput id="thumbnail" label="Upload Thumbnail" src={lesson.thumbnail} accept="image/png,image/jpg,image/jpeg,.svg" handleChange={(e) => previewImage(e, "thumbnail")} />
        </div>
        <div className="col-md-6 mt-3"></div>

        <div className="col-md-6 mt-3">
          <CustomInput id="class" name="class" type="text" label="Class" readOnly value={classDetails ? classDetails.name : ""} />
        </div>

        <div className="col-md-6 mt-3">
          <CustomInput id="subject" name="subject" type="text" label="Subject" readOnly value={lesson.subject} />
        </div>

        <div className="col-md-6 mt-3">
          <CustomInput id="topic" name="topic" type="text" label="Topic" value={lesson.topic} readOnly={readOnly} handleChange={setTopic} />
        </div>

        <div className="col-md-6 mt-3">
          <CustomSelectInput id="term" name="term" dropDown={termDropdown()} label="Term" selected={{ text: terms[lesson.term], value: lesson.term }} onChange={setTerm} readOnly={readOnly} />
        </div>

        <div className="col-md-6 mt-3">
          <CustomSelectInput id="content-type" name="content_type" dropDown={contentDropdown()} label="Content Type" selected={{ text: contentTypes[lesson?.content_type], value: lesson?.content_type }} onChange={setContentType} readOnly={readOnly} />
        </div>

        <div className="col-md-6 mt-3">
          <CustomSelectInput id="tutor" name="tutor_id" dropDown={tutorDropdown(tutors)} label="Tutor" selected={{ text: `${tutors[lesson.tutor_id]?.first_name} ${tutors[lesson.tutor_id]?.last_name}`, value: lesson.tutor_id }} onChange={setTutorId} readOnly={readOnly} />
        </div>

        <div className="col-md-6 mt-3">
          <CustomTextarea id="description" name="description" type="text" placeholder="Description" label="Description" value={lesson.description} handleChange={setDescription} readOnly={readOnly} />
        </div>

        {/* <div className="col-md-6 mt-3">
          <CustomInput
            id="tutor-name"
            name="tutor-name"
            type="text"
            label="Tutor Name"
            value={lesson.tutor_name}
            handleChange={setTutorName}
            readOnly={readOnly}
          />
        </div> */}

        <div className="col-md-6 mt-3">
          <CustomInput id="preview-duration" name="preview-duration" type="number" label="Preview Duration" value={lesson.preview_duration} handleChange={setPreviewDuration} readOnly={readOnly} />
        </div>

        {/* <div className="col-md-6 mt-3">
          <CustomTextarea
            id="tutor-bio"
            name="tutor-bio"
            type="text"
            placeholder=""
            label="Tutor Bio"
            value={lesson.tutor_bio}
            handleChange={setTutorBio}
            readOnly={readOnly}
          />
        </div> */}

        <div className="col-md-6 mt-3">
          <div id="tag-cover" className="form-group">
            <label>Tags/Keywords</label>
            <TagInput
              tagProps={{
                color: "neutral",
                height: "30px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "17px",
                fontFamily: "Avenir",
                paddingX: "10px",
              }}
              marginTop="1rem"
              backgroundColor="#fff"
              height={40}
              width="100%"
              borderRadius={7}
              border="none"
              outline="none"
              inputProps={{
                placeholder: "Add tag...",
                color: "#ff6c00",
                fontSize: "17px",
              }}
              readOnly={readOnly}
              values={lesson.tags}
              onChange={(values) => {
                setTags(values);
              }}
            />
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div id="download-toggle-cover" className="form-group">
            <label>Download on Device</label>
            <label className="switch">
              <input type="checkbox" id="download-toggle" value={lesson.downloadable} onChange={setDownloadable} disabled={readOnly} />
              <span className="slider round" />
            </label>
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <CustomInput id="preview-duration" name="price" type="number" label="Course module price" value={lesson.price} handleChange={setPrice} readOnly={readOnly} />
        </div>

        <hr />

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <label>Videos</label>
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <label>Documents</label>
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <LessonFiles readOnly={readOnly} lessonId={lesson.id} files={lesson.videos} updateFiles={(videos) => updateLesson("videos", videos)} type="videos" />
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <LessonFiles readOnly={readOnly} lessonId={lesson.id} files={lesson.documents} updateFiles={(documents) => updateLesson("documents", documents)} type="documents" accepts="application/pdf" />
          </div>
        </div>

        <hr />

        {lesson.approved === false && (
          <div id="publish-cover" className="publish mt-4">
            {!loading ? (
              <button type="button" className="btn-primary m-2" onClick={() => lessonAction(true)}>
                APPROVE
              </button>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
            {!loading ? (
              <button type="button" className="btn-primary m-2" onClick={() => lessonAction(false)}>
                DECLINE
              </button>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
          </div>
        )}

        {!readOnly && (
          <div id="publish-cover" className="publish mt-4">
            {!loading ? (
              <button type="button" className="btn-primary m-2" onClick={() => submitForm(true)}>
                SAVE AS DRAFT
              </button>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
            {!loading ? (
              <button type="button" className="btn-primary" onClick={() => submitForm(false)}>
                PUBLISH
              </button>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
          </div>
        )}
      </form>
    )
  );
};

const mapStateToProps = ({ dashboard }) => ({
  loading: dashboard.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  addToFileQueue: (file) => dispatch(addToFileQueue(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveLessonForm);
